<template>
  <div>
    <loading
        :active.sync="isLoading"
        :is-full-page="fullPage"
        :loader="$store.state.spinerLoader"
        :color="$store.state.spinerColor"
    ></loading>
    <vue-snotify></vue-snotify>


    <CCard class="card-border">



      <div
          class="rest-bg-imge"
          v-bind:style="{ backgroundImage: 'url(' + menuBackgroundImage + ')' }"
      >

        <div class=" c-header-fixed"  style="z-index: 999 ">
          <div  class="mobile-view menu-header" style="background-color: #090331">
            <CCol class="col-md-8 d-flex ">
              <h5 class="text-white mr-2" style="padding-left: 0px; font-size: 12px" @click="$router.push({name: 'RestaurantBookingForm',params: { tenant_id: tenant_id },})">{{restaurant_name}}</h5>
              <img src="../../assets/icons/info.svg" alt="Info Icon"
                   style="width: 16px; height: 16px; cursor: pointer;"
                   @click="$router.push({name: 'RestaurantBookingForm',params: { tenant_id: tenant_id },})" />

            </CCol>

            <CCol class="col-md-4">

              <div class="d-flex justify-content-end align-items-center">

                <div>
                  <!-- Meal Dropdown -->
                  <CDropdown
                      class="custom-dropdown dropdown"
                      color="btn-sm"
                      :toggler-text="mealMap[selectedMeal]"
                      style="position: relative !important; display: block !important;"
                  >
                    <div>
                      <CDropdownItem @click="setActivebtn(1)">
                        <div class="text-white">Breakfast</div>
                      </CDropdownItem>
                      <CDropdownItem @click="setActivebtn(2)">
                        <div class="text-white">Lunch</div>
                      </CDropdownItem>
                      <CDropdownItem @click="setActivebtn(3)">
                        <div class="text-white">Evening</div>
                      </CDropdownItem>
                      <CDropdownItem @click="setActivebtn(4)">
                        <div class="text-white">Dinner</div>
                      </CDropdownItem>
                    </div>
                  </CDropdown>
                </div>


                <div class="d-flex align-items-end pl-2 pr-0">
                  <div class="pr-0">

                    <CButton
                        color="success"
                        class="custom-button"
                        :disabled="SOSDisable"
                        @click="callWaiter(); checkSOSStatus();"
                        v-if="customer_access_token && active_reservation_id"
                    >
                      Call Waiter <CIcon name="cil-phone" title="call waiter" />
                    </CButton>
                  </div>
                </div>
              </div>
            </CCol>
          </div>


          <div class="pb-1" style="background-color: #090331">
            <div class="mb-1 meal-plan" style="display: flex; overflow-x: scroll; overflow-y: auto; scroll-behavior: smooth;">

              <!-- Search Button and Input Field -->
              <div style="position: relative; display: flex; align-items: center;">
                <button
                    v-if="!showSearchInput"
                    class="search-btn"
                    @click="toggleSearchInput"
                    style="background-color: blueviolet; color: white; height: 25px; border-radius: 12px; padding: 0 12px; display: flex; align-items: center; justify-content: center; margin-right: 4px; font-size: 12px; border: none; cursor: pointer; margin-left: 5px; margin-bottom: 0px"
                >
                  <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-search"
                      viewBox="0 0 16 16"
                      style="margin-right: 4px;"
                  >
                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001l3.85 3.85a1 1 0 0 0 1.415-1.415l-3.85-3.85zm-5.477 1.31a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11z" />
                  </svg>
                </button>

                <!-- Input with clear button -->
                <div v-if="showSearchInput" style="position: relative; display: flex; align-items: center;">
                  <input
                      type="text"
                      class="search-input"
                      placeholder="Search..."
                      v-model="searchQuery"
                      @input="onSearchInputChange"

                      style="height: 25px; border-radius: 12px; padding: 0 28px 0 8px; margin-right: 4px; border: 1px solid #ced4da; font-size: 14px; margin-left: 5px; margin-bottom: 3px; width: 150px;"
                  />
                  <button
                      class="clear-btn"
                      @click="clearSearch"
                      style="position: absolute; right: 10px; background: none; border: none; cursor: pointer; padding: 0px 0px 6px 0px;"
                  >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        fill="currentColor"
                        class="bi bi-x-circle"
                        viewBox="0 0 16 16"
                    >
                      <path d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 1 8 0a8 8 0 0 1 0 16z" />
                      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                  </button>
                </div>

                <!-- "All" Button -->
<!--                <button-->
<!--                    v-if="searchQuery"-->
<!--                    class="search-btn"-->
<!--                    @click="clearSearch"-->
<!--                    style="background-color: blueviolet; color: white; height: 20px; border-radius: 12px; padding: 0 12px; display: flex; align-items: center; justify-content: center; margin-left: 4px; font-size: 12px; border: none; cursor: pointer;"-->
<!--                >-->
<!--                  All-->
<!--                </button>-->
              </div>







              <!-- Sub-category Buttons -->
              <CButton
                  v-if="categoryItem.menu_items.length &&
          categoryItem.status == 1 &&
          (categoryItem.billing || (customer_access_token && active_reservation_id))"
                  v-scroll-to="{
        el: '#category_div_' + categoryItem.id + '',
        container: '#scrolling-wrapper',
        duration: 500,
        lazy: false,
        offset: -30,
      }"
                  :color="getDynamicCategoryBtnColor(categoryItem.id)"
                  class="ml-1 mt-1 sub-category-btn mb-1"
                  v-for="(categoryItem, index) in restaurantMenuCategory"
                  shape="pill"
                  style="height: 25px; display: flex; align-items: center; justify-content: center; font-size: 12px"
                  @click="setActiveMenuCategoryBtn(categoryItem.id)"
              >
                {{ categoryItem.title }}
              </CButton>

            </div>
          </div>
        </div>


        <div class="resturent-main-card">

          <div v-if="categoryItem.menu_items.length &&
          categoryItem.status == 1 &&
          (categoryItem.billing || (customer_access_token && active_reservation_id))"

          class="pr-3 pl-3"
               v-for="(categoryItem, index) in restaurantMenuCategory" :key="categoryItem.id" :id="'category-' + categoryItem.id">
            <CCard class="card-item-new shadow-card mb-3">

              <CRow>
                <CCol>
                  <div style="display: flex; justify-content: space-between; align-items: center;">
                    <div style="font-size: 16px; font-weight: bold;">
                      {{ categoryItem.title }} - ({{ categoryItem.menu_items.length }})
                    </div>
                    <button v-if="categoryItem.menu_items.length > 3" class="btn-outline-primary1" @click="openSeeAllModal(index)" style="border: 2px solid blueviolet; color: blueviolet; background-color: transparent; padding: 0px 10px; font-size: 12px; border-radius: 4px; transition: box-shadow 0.3s ease;">
                      {{ showAllItems[index] ? 'Show Less' : 'See All' }}
                    </button>
                  </div>
                </CCol>
              </CRow>


              <CRow>
                <CCol>
                  <CCard v-for="(item, idx) in visibleItems(categoryItem, index)" :key="idx" class="mt-3 shadow-card"  >
                    <CRow >
                      <!-- Image Section -->
                      <div
                          @click="openMenuItemModal(item.item)"
                          style="position: relative; width: 100px; height: 100px; border-radius: 12px; overflow: hidden; background-color: #f0f0f0;"
                      >
                        <img
                            v-if="item.item.image_collection[0]"
                            class="restaurant-img"
                            :src="item.item.image_collection[0].url"
                            style="width: 100%; height: 100%; object-fit: cover; overflow: hidden;  border-radius: 12px;"
                        >
                      </div>

                      <CCol style="margin: 0; padding: 5px;">
                        <h6 class="m-0 mr-3" style="margin: 0;" @click="openMenuItemModal(item.item)">{{ item.item.title }}</h6>
                        <CRow style="margin: 0; padding: 5px;">
                          <p class="text-muted m-0 mr-2" style="margin: 0; font-size: 10px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                            {{ item.item?.description }}
                          </p>
                        </CRow>

                        <div class="pl-3" v-if="item.item.item_attributes.length < 1">
                          <h6 class="d-flex align-items-center">
                             <span v-if="item.item.price !== 0">
                            Rs {{ item.item?.price}}
                             </span>

                            <span v-if="item.item.price !== 0 && customer_access_token && active_reservation_id" class="icon-box ml-2 "  style="padding: 8px 16px; display: inline-flex; align-items: center; justify-content: center; flex: 0 0 auto; margin-left: auto;" >
                                  <i class="fas fa-plus" @click.stop.prevent="
                                      checkOrderModalShow(false);
                                      setMenuItem(item.item);
                                      selectVariant(item.item.item_attributes[0], item.item)
                                    "></i>
                            </span>

                            <span v-if="item.item.price === 0" class="ml-2" >
                                <button @click.stop.prevent="checkOrderModalShow(false); setMenuItem(item.item); selectVariant(item.item.item_attributes[0], item.item)" style="font-size: 10px; padding: 5px 8px; border: none; background-color: blueviolet; color: white; border-radius: 4px; cursor: pointer;">
                                      Request
                                </button>
                            </span>

                          </h6>
                        </div>


                        <div v-for="(variation, variationIndex) in item.item.item_attributes" :key="variation.id">
                          <CRow
                              class="pl-3 align-items-baseline"
                              v-for="(atrItem, atrItemIndex) in visibleItems1(variation, variationIndex)"
                              :key="atrItemIndex"
                              :class="{'hidden-rows': atrItemIndex > 1 && !showAllRows[variation.id]}"
                          >
                            <div class="small-card">
                              {{ atrItem.attribute_value.name }}
                            </div>


                            <div class="pl-0">
                              <div class="row d-flex align-content-between" style="display: flex; flex-wrap: nowrap;">
                                <span class="ml-2 d-flex justify-content-end" style="flex: 0 0 70px; text-align: left; font-weight: bold; font-size: 12px">
                                   Rs {{ atrItem.menu_item_price }}
                                </span>
                                <span v-if="customer_access_token && active_reservation_id" class="icon-box ml-1"
                                      style="padding: 8px 16px; display: inline-flex; align-items: center; justify-content: center; flex: 0 0 auto; margin-left: auto;">
                                      <i class="fas fa-plus" @click.stop.prevent="selectVariant(atrItem, item.item);checkOrderModalShow(true);setMenuItem(item.item);"></i>
                                </span>

                                <!-- Show toggle icons only when needed -->
                                <span v-if="(!showAllRows[variation.id] && atrItemIndex === 1) || (showAllRows[variation.id] && atrItemIndex === variation.attribute_values.length - 1)">
                                  <i v-if="!showAllRows[variation.id]" class="fa fa-angle-double-down ml-1" aria-hidden="true" @click="toggleRows(variation.id)"></i>
                                  <i v-if="showAllRows[variation.id]" class="fa fa-angle-double-up ml-2" aria-hidden="true" @click="toggleRows(variation.id)"> </i>
                                </span>
                              </div>
                            </div>


                          </CRow>
                        </div>









                      </CCol>
                    </CRow>
                  </CCard>
                </CCol>
              </CRow>
            </CCard>
          </div>

        </div>

      </div>

    </CCard>


    <div class="card-border cart-button-container" v-if="
          customer_access_token && active_reservation_id &&
          hasPermission('CUSTOMER_ORDER') &&
          hasPermission(tenant_id)
        ">
      <div style="width: 98%; background-color: transparent;" class="d-flex justify-content-end ml-2 " >
        <div style="width: 100%; background-color: transparent;">
          <div style="width: 100%; background-color: transparent;">
            <div style="background-color: transparent;">
              <TheHeaderDropdownCartNew class="view-cart-button" />
            </div>
          </div>
        </div>
      </div>
    </div>




<!--    <CModal-->
<!--        :title="`${selectedCategory?.title} - (${selectedCategory?.menu_items?.length})`"-->
<!--        :show.sync="false"-->
<!--        :closeOnBackdrop="false"-->
<!--        class="custom-modal"-->
<!--        color="dark"-->
<!--    >-->
<!--      <template #default>-->

<!--        <div v-if="selectedCategory" class="modal-body"  style="height: 300px; overflow-y: auto;">-->
<!--          <CCard class="card-item-new shadow-card mb-3">-->
<!--            <CRow>-->
<!--              <CCol>-->
<!--                <CCard v-for="(item, idx) in visibleItemsAll(selectedCategory, index)" :key="idx" class="mt-3 shadow-card">-->
<!--                  <CRow>-->

<!--                      &lt;!&ndash; Image Section &ndash;&gt;-->
<!--                      <div  @click="openMenuItemModal(item.item)" style="position: relative;width: 100px;height: 100px;border-radius: 12px;overflow: hidden;">-->
<!--                        <img-->
<!--                            v-if="item.item.image_collection[0]"-->
<!--                            class="restaurant-img"-->
<!--                            :src="item.item.image_collection[0].url"-->
<!--                            style="position: absolute;top: 50%;left: 50%;width: 100%;height: 100%;object-fit: cover;transform: translate(-50%, -50%);"-->
<!--                        >-->
<!--                      </div>-->


<!--                    <CCol style="margin: 0; padding: 5px;">-->
<!--                      <h6 class="m-0" style="margin: 0;" @click="openMenuItemModal(item.item)">{{ item.item.title }}</h6>-->
<!--                      <CRow style="margin: 0; padding: 5px;">-->
<!--                        <p  class="text-muted m-0 mr-2" style="margin: 0; font-size: 10px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{ item.item.description }}</p>-->
<!--                      </CRow>-->

<!--                      <div class="pl-3" v-if="item.item.item_attributes.length < 1">-->
<!--                        <h6 class="d-flex align-items-center">-->
<!--                      <span v-if="item.item.price !== 0">-->
<!--                        Rs {{ item.item.price }}-->
<!--                      </span>-->

<!--                          <span v-if="item.item.price !== 0" class="icon-box ml-2" @click="selectVariant(item.item.item_attributes[0], item.item)">-->
<!--                        <i class="fas fa-plus" @click.stop.prevent="checkOrderModalShow(false); setMenuItem(item.item);"></i>-->
<!--                      </span>-->

<!--                          <span v-if="item.item.price === 0" class="ml-2" @click="selectVariant(item.item.item_attributes[0], item.item)">-->
<!--                        <button @click.stop.prevent="checkOrderModalShow(false); setMenuItem(item.item)" style="font-size: 10px; padding: 5px 8px; border: none; background-color: blueviolet; color: white; border-radius: 4px; cursor: pointer;">-->
<!--                          Request-->
<!--                        </button>-->
<!--                      </span>-->
<!--                        </h6>-->
<!--                      </div>-->

<!--                      <div v-for="(variation, index) in item.item.item_attributes">-->

<!--                        <CRow-->
<!--                            class="pl-3 align-items-baseline"-->
<!--                            v-for="(atrItem, atrItemIndex) in visibleItems1(variation, variationIndex)"-->
<!--                            :key="atrItemIndex"-->
<!--                            :class="{'hidden-rows': atrItemIndex > 1 && !showAllRows[variation.id]}"-->
<!--                        >-->
<!--                          <div class="small-card">-->
<!--                            {{ atrItem.attribute_value.name }}-->
<!--                          </div>-->


<!--                          <div class="pl-0">-->
<!--                            <div class="row d-flex align-items-end" style="display: flex; flex-wrap: nowrap;">-->
<!--                                <span class="ml-2 d-flex justify-content-end" style="flex: 0 0 70px; text-align: left; font-weight: bold; font-size: 12px">-->
<!--                                   Rs {{ atrItem.menu_item_price }}-->
<!--                                </span>-->
<!--                              <span class="icon-box ml-1"-->
<!--                                    style="padding: 8px 16px; display: inline-flex; align-items: center; justify-content: center; flex: 0 0 auto; margin-left: auto;">-->
<!--                                      <i class="fas fa-plus" @click.stop.prevent="selectVariant(atrItem, item.item);checkOrderModalShow(true);setMenuItem(item.item);"></i>-->
<!--                                </span>-->

<!--                              &lt;!&ndash; Show toggle icons only when needed &ndash;&gt;-->
<!--                              <span v-if="(!showAllRows[variation.id] && atrItemIndex === 1) || (showAllRows[variation.id] && atrItemIndex === variation.attribute_values.length - 1)">-->
<!--                                  <i v-if="!showAllRows[variation.id]" class="fa fa-angle-double-down ml-1" aria-hidden="true" @click="toggleRows(variation.id)"></i>-->
<!--                                  <i v-if="showAllRows[variation.id]" class="fa fa-angle-double-up ml-2" aria-hidden="true" @click="toggleRows(variation.id)"> </i>-->
<!--                                </span>-->
<!--                            </div>-->
<!--                          </div>-->


<!--                        </CRow>-->


<!--                      </div>-->
<!--                    </CCol>-->
<!--                  </CRow>-->
<!--                </CCard>-->
<!--              </CCol>-->
<!--            </CRow>-->
<!--          </CCard>-->
<!--        </div>-->
<!--      </template>-->

<!--      <template #footer>-->
<!--        <CButton color="dark" @click="showSeeAllModal = false">Close</CButton>-->
<!--      </template>-->
<!--    </CModal>-->





    <CModal
        :show.sync="showSeeAllModal"
        :no-close-on-backdrop="true"
        :centered="true"
        :title="`${selectedCategory?.title} - (${selectedCategory?.menu_items?.length})`"
        size="lg"
        color="dark"
        addContentClasses="mr-3"
    >

      <template #default>

        <div v-if="selectedCategory" style="height: 400px; overflow-y: auto;">
          <CCard class="card-item-new shadow-card mb-3">
            <CRow>
              <CCol>
                <CCard v-for="(item, idx) in visibleItemsAll(selectedCategory, index)" :key="idx" class="mt-3 shadow-card">
                  <CRow>

                    <!-- Image Section -->
                    <div  @click="openMenuItemModal(item.item)" style="position: relative;width: 100px;height: 100px;border-radius: 12px;overflow: hidden;">
                      <img
                          v-if="item.item.image_collection[0]"
                          class="restaurant-img"
                          :src="item.item.image_collection[0].url"
                          style="position: absolute;top: 50%;left: 50%;width: 100%;height: 100%;object-fit: cover;transform: translate(-50%, -50%);"
                      >
                    </div>


                    <CCol style="margin: 0; padding: 5px;">
                      <h6 class="m-0" style="margin: 0;" @click="openMenuItemModal(item.item)">{{ item.item.title }}</h6>
                      <CRow style="margin: 0; padding: 5px;">
                        <p  class="text-muted m-0 mr-2" style="margin: 0; font-size: 10px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{ item.item.description }}</p>
                      </CRow>

                      <div class="pl-3" v-if="item.item.item_attributes.length < 1">
                        <h6 class="d-flex align-items-center">
                      <span v-if="item.item.price !== 0">
                        Rs {{ item.item.price }}
                      </span>

                          <span v-if="item.item.price !== 0 && customer_access_token && active_reservation_id" class="icon-box ml-2" @click="selectVariant(item.item.item_attributes[0], item.item)">
                        <i class="fas fa-plus" @click.stop.prevent="checkOrderModalShow(false); setMenuItem(item.item);"></i>
                      </span>

                          <span v-if="item.item.price === 0" class="ml-2" @click="selectVariant(item.item.item_attributes[0], item.item)">
                        <button @click.stop.prevent="checkOrderModalShow(false); setMenuItem(item.item)" style="font-size: 10px; padding: 5px 8px; border: none; background-color: blueviolet; color: white; border-radius: 4px; cursor: pointer;">
                          Request
                        </button>
                      </span>
                        </h6>
                      </div>

                      <div v-for="(variation, index) in item.item.item_attributes">

                        <CRow
                            class="pl-3 align-items-baseline"
                            v-for="(atrItem, atrItemIndex) in visibleItems1(variation, variationIndex)"
                            :key="atrItemIndex"
                            :class="{'hidden-rows': atrItemIndex > 1 && !showAllRows[variation.id]}"
                        >
                          <div class="small-card">
                            {{ atrItem.attribute_value.name }}
                          </div>


                          <div class="pl-0">
                            <div class="row d-flex align-items-end" style="display: flex; flex-wrap: nowrap;">
                                <span class="ml-2 d-flex justify-content-end" style="flex: 0 0 70px; text-align: left; font-weight: bold; font-size: 12px">
                                   Rs {{ atrItem.menu_item_price }}
                                </span>
                              <span v-if="customer_access_token && active_reservation_id" class="icon-box ml-1"
                                    style="padding: 8px 16px; display: inline-flex; align-items: center; justify-content: center; flex: 0 0 auto; margin-left: auto;">
                                      <i class="fas fa-plus" @click.stop.prevent="selectVariant(atrItem, item.item);checkOrderModalShow(true);setMenuItem(item.item);"></i>
                                </span>

                              <!-- Show toggle icons only when needed -->
                              <span v-if="(!showAllRows[variation.id] && atrItemIndex === 1) || (showAllRows[variation.id] && atrItemIndex === variation.attribute_values.length - 1)">
                                  <i v-if="!showAllRows[variation.id]" class="fa fa-angle-double-down ml-1" aria-hidden="true" @click="toggleRows(variation.id)"></i>
                                  <i v-if="showAllRows[variation.id]" class="fa fa-angle-double-up ml-2" aria-hidden="true" @click="toggleRows(variation.id)"> </i>
                                </span>
                            </div>
                          </div>


                        </CRow>


                      </div>
                    </CCol>
                  </CRow>
                </CCard>
              </CCol>
            </CRow>
          </CCard>
        </div>
      </template>

      <template #footer>
        <button
            class="search-btn"
            @click="showSeeAllModal = false"
            style="background-color: blueviolet; color: white; height: 25px; border-radius: 4px; padding: 0 12px; display: flex; align-items: center; justify-content: center; margin-right: 4px; font-size: 12px; border: none; cursor: pointer; margin-left: 5px; margin-bottom: 0px"
        >Cancel</button>
      </template>
    </CModal>























    <!-- Order Modal -->
    <CModal
        :show.sync="showOrderModal"
        :no-close-on-backdrop="true"
        :centered="true"
        title="Modal title 2"
        size="lg"
        color="dark"
    >
      <CRow class="d-flex align-content-center">


        <CCol md="12" class="d-flex align-content-center " style="gap: 1rem;">

          <div class="small-card " style="color: white; background-color: blueviolet; border-color: black" v-if="showVar">
              {{active_variation?.attribute_value?.name}}
          </div>


          <div class="">
            <p class="text-white">
              <b>
                <span v-if="selectedMenuItem.discount">
                  <del class="mr-2">{{
                      selectedMenuItem.price | currency("Rs ")
                    }}</del>
                  <span>{{
                      (selectedMenuItem.price -
                          (selectedMenuItem.discount * selectedMenuItem.price) /
                          100)
                          | currency("Rs ")
                    }}</span>
                </span>
                <span v-if="!selectedMenuItem.discount">
                  {{ selectedMenuItem.price | currency("Rs ") }}
                </span>
              </b>
            </p>
          </div>

        </CCol>

        <CCol md="12">
          <CTextarea
              class="text-white"
              label="Special Note"
              placeholder="Type here..."
              rows="4"
              v-model="order_comment"
          />
        </CCol>
        <CCol md="12">
          <CButton
              color="light"
              size="md"
              variant="outline"
              @click="decrementMenuItemCount"
          >-</CButton
          >
          <CButton color="light" size="md" disabled class="ml-1">{{
              menuItemCount
            }}</CButton>
          <CButton
              color="light"
              size="md"
              variant="outline"
              class="ml-1"
              @click="incrementMenuItemCount"
          >+</CButton
          >
        </CCol>
      </CRow>

      <template #header>
        <h6 class="modal-title">{{ selectedMenuItem.title }}</h6>
        <CButtonClose @click="showOrderModal = false" class="text-white" />
      </template>
      <template #footer>
        <span
            @click="
            showOrderModal = false;
            addTocart();
          "
            color="primary"
            class="text-white"
        >Add {{ menuItemCount }} to Order (

          <span v-if="selectedMenuItem.discount">
            <del class="mr-2">{{
                (selectedMenuItem.price * menuItemCount) | currency("Rs ")
              }}</del>
            <span>{{
                ((selectedMenuItem.price -
                        (selectedMenuItem.discount * selectedMenuItem.price) / 100) *
                    menuItemCount)
                    | currency("Rs ")
              }}</span>
          </span>
          <span v-if="!selectedMenuItem.discount">
            {{ (selectedMenuItem.price * menuItemCount) | currency("Rs ") }}
          </span>

          )</span
        >
        <CButton @click="showOrderModal = false" variant="outline" color="light"
        >Close</CButton
        >
        <CButton
            @click="
            showOrderModal = false;
            confirmOrderItem();
          "
            color="primary"
        >
          Confirm Order <CIcon name="cil-check" />
        </CButton>
      </template>
    </CModal>

    <!-- Order Modal -->
    <CModal
        :show.sync="foodDetailsModal"
        :no-close-on-backdrop="true"
        :centered="true"
        title="Modal title 2"
        size="lg"
        color="dark"
        addContentClasses="mr-3"
    >




      <div class="text-white"
           style="display: flex; flex-direction: column; align-items: center; text-align: left; height: 100%;">

        <div  style="position: relative;width: 200px;height: 200px;border-radius: 12px;overflow: hidden;">
          <img
              v-if="viewMenuItem?.image_collection?.length > 0"
              class="restaurant-img"
              :src="viewMenuItem.image_collection[0].url"
              style="position: absolute;top: 50%;left: 50%;width: 100%;height: 100%;object-fit: cover;transform: translate(-50%, -50%);"
          >
        </div>




        <p style="margin-top: 8px; align-self: flex-start;">{{ viewMenuItem.description }}</p>



        <div class="pl-3" v-if="viewMenuItem?.item_attributes?.length < 1" style="width: 100%; text-align: left;">
          <h6>
            <span v-if="viewMenuItem.price !== 0" class="mr-4"
                  style="text-align: left; white-space: nowrap; flex: 0 1 auto;">Rs {{ viewMenuItem.price }}
            </span>

<!--             If price is zero, display only the icon (no price) -->
            <span v-if="viewMenuItem.price === 0" class="ml-2" @click="selectVariant(viewMenuItem.item_attributes[0], viewMenuItem)">
                        <button @click.stop.prevent="checkOrderModalShow(false); setMenuItem(viewMenuItem); " style="font-size: 10px; padding: 5px 8px; border: none; background-color: blueviolet; color: white; border-radius: 4px; cursor: pointer;">
                          Request
                        </button>
                      </span>

<!--             If price is not zero, show the plus button -->
            <span v-if="viewMenuItem.price !== 0 && customer_access_token && active_reservation_id" class="icon-box"
                  style="padding: 8px 24px; display: inline-flex; align-items: center; justify-content: center; flex: 0 0 auto; margin-left: auto;">
              <i class="fas fa-plus" style="font-size: 12px; color: white; cursor: pointer;"
                   @click.stop.prevent=";checkOrderModalShow(false);
                                        setMenuItem(viewMenuItem);selectVariant(viewMenuItem?.item_attributes[0], viewMenuItem); foodDetailsModal = false">
                </i>
            </span>

          </h6>
        </div>


        <div v-for="(variation, index) in viewMenuItem.item_attributes" style="width: 100%; text-align: left;">
          <CRow
              class="pl-3 align-items-baseline"
              v-for="(atrItem, indexAtr) in visibleItems2(variation, index)"
              :key="indexAtr"
              :class="{'hidden-rows': indexAtr > 1 && !showAllRows}"
              style="align-items: flex-start;">

            <div class="small-card">
              <span class="truncate-text">{{ atrItem.attribute_value.name }}</span>
            </div>


            <div class="pl-3" style="flex: .5;">
              <h6 class="d-flex align-items-center" style="display: flex; justify-content: space-between; width: 100%;">
                <span style="text-align: left; flex: 0 1 auto; white-space: nowrap;">Rs {{ atrItem.menu_item_price }}</span>
                <span v-if="customer_access_token && active_reservation_id" class="icon-box"
                      style="padding: 8px 28px; display: inline-flex; align-items: center; justify-content: center; flex: 0 0 auto;">
                        <i class="fas fa-plus" style="font-size: 12px; color: white; cursor: pointer;"
                           @click.stop.prevent="selectVariant(atrItem, viewMenuItem);
                                                checkOrderModalShow();
                                                setMenuItem(viewMenuItem);
                                                foodDetailsModal = false">
                        </i>
                    </span>

              </h6>
            </div>
          </CRow>


        </div>
      </div>


      <template #header>
        <h6 class="modal-title">{{ viewMenuItem.title }}</h6>
        <CButtonClose @click="foodDetailsModal = false" class="text-white" />
      </template>
      <template #footer>
        <p> </p>
        <!--        <CButton-->
        <!--            @click="foodDetailsModal = false"-->
        <!--            variant="outline"-->
        <!--            color="light"-->
        <!--        >Close</CButton-->
        <!--        >-->
      </template>
    </CModal>

    <!-- Validate reservation number modal -->
    <CModal
        :show.sync="validateReservationNumberModal"
        :closeOnBackdrop="false"
        :centered="true"
        title=""
        size="lg"
        color="dark"
        addContentClasses="mr-3"
    >
      <CRow>
        <CCol md="12" class="m-3">
          <div class="form-group">
            <CInputRadioGroup
                class="text-white"
                :options="[
                {
                  value: 'Yes',
                  label: '(YES) ',
                  props: { description: '  If you have reservation number ' },
                },
                {
                  value: 'No',
                  label: '(NO) ',
                  props: {
                    description: '   If you do not have reservation number ',
                  },
                },
              ]"
                @update:checked="reservationChecked"
                inline
                :checked="radioValidate"
            />
          </div>
        </CCol>
      </CRow>

      <div v-if="radioValidate == 'Yes'">
        <ValidationObserver ref="reservation_number_checking_form">
          <ValidationProvider
              vid="reservation_number"
              name="Reservation Number"
              v-slot="{ errors }"
              rules="required"
          >
            <CRow>
              <CCol md="4" class="mt-4">
                <label class="text-white">Enter Your Reservation Number</label>
              </CCol>
              <CCol md="8">
                <!-- <v-otp-input
                  inputClasses="otp-input"
                  :numInputs="4"
                  separator="-"
                  :shouldAutoFocus="true"
                  @on-complete="handleOnComplete"
                /> -->
                <br />
                <input
                    class="text-center otp-txt-box"
                    type="text"
                    v-mask="'#-#-#-#'"
                    v-model="otp_mask"
                    placeholder="-  -  -  -"
                />

                <div v-if="errors[0]" class="wizard-error">
                  <span v-for="(error, index) in errors" v-bind:key="index">
                    {{ error }}
                  </span>
                </div>
              </CCol>
              <CCol col="4"> </CCol>
              <CCol col="8"> </CCol>
            </CRow>
          </ValidationProvider>
        </ValidationObserver>
      </div>
      <div v-if="radioValidate == 'No'">
        <div v-if="check_table_availability == true">
          <div v-if="customer_access_token && !reservation_id">
            <CButton @click="makeNewReservation" color="primary"
            >Make an On-time Reservation</CButton
            >
          </div>

          <form-wizard
              :start-index="0"
              ref="wizard"
              title=""
              subtitle=""
              color="#7F30BF"
              v-show="!customer_access_token"
              class="custom-wizard-menu"
          >
            <tab-content title="Personal details" :before-change="genereateOtp">
              <ValidationObserver ref="step1_form">
                <div v-show="mobile_show">
                  <CRow>
                    <CCol md="4">
                      <label class="text-white"
                      >Do you have ScanMe account?</label
                      >
                    </CCol>
                    <CCol md="8">
                      <ValidationProvider
                          vid="account_exists"
                          name="ScanMe account exist"
                          v-slot="{ errors }"
                          rules=""
                      >
                        <CSwitch
                            labelOn="Yes"
                            labelOff="No"
                            @update:checked="accountExists"
                            :checked.sync="account_exists"
                            color="primary"
                            class="mb-2"
                        />
                        <input type="hidden" v-model="account_exists" />
                        <div v-if="errors[0]" class="wizard-error mt-2 mb-3">
                          <span
                              v-for="(error, index) in errors"
                              v-bind:key="index"
                          >
                            {{ error }}
                          </span>
                        </div>
                      </ValidationProvider>
                    </CCol>
                  </CRow>

                  <ValidationProvider
                      vid="mobile_no"
                      name="mobile no"
                      v-slot="{ errors }"
                      rules="required"
                  >
                    <CRow>
                      <CCol md="4">
                        <label class="sign-up-lable text-white"
                        >Enter your mobile no (required)</label
                        >
                      </CCol>
                      <CCol md="8">
                        <CInput
                            size="lg"
                            v-model="form_params.mobile_no"
                            v-mask="'+94#########'"
                            placeholder="(+94)-XXXXXXXXX"
                            :is-valid="checkValidInput(errors)"
                        />

                        <div v-if="errors[0]" class="wizard-error">
                          <span
                              v-for="(error, index) in errors"
                              v-bind:key="index"
                          >
                            {{ error }}
                          </span>
                        </div>
                      </CCol>
                    </CRow>
                  </ValidationProvider>
                  <CRow v-if="customerAccountExist == 'No'">
                    <CCol md="6">
                      <div class="form-group">
                        <ValidationProvider
                            vid="first_name"
                            name="first name"
                            v-slot="{ errors }"
                            rules="required"
                        >
                          <label class="sign-up-lable text-white"
                          >Enter your first name (required)</label
                          >
                          <CInput
                              size="lg"
                              placeholder="First Name"
                              v-model="first_name"
                              :is-valid="checkValidInput(errors)"
                          />
                          <div v-if="errors[0]" class="wizard-error">
                            <span
                                v-for="(error, index) in errors"
                                v-bind:key="index"
                            >
                              {{ error }}
                            </span>
                          </div>
                        </ValidationProvider>
                      </div>
                    </CCol>
                    <CCol md="6">
                      <div class="form-group">
                        <ValidationProvider
                            vid="last_name"
                            name="last name"
                            v-slot="{ errors }"
                            rules="required"
                        >
                          <label class="sign-up-lable text-white"
                          >Enter your last name (required)</label
                          >
                          <CInput
                              size="lg"
                              placeholder="Last Name"
                              v-model="last_name"
                              :is-valid="checkValidInput(errors)"
                          />
                          <div v-if="errors[0]" class="wizard-error">
                            <span
                                v-for="(error, index) in errors"
                                v-bind:key="index"
                            >
                              {{ error }}
                            </span>
                          </div>
                        </ValidationProvider>
                      </div>
                    </CCol>
                  </CRow>
                </div>
              </ValidationObserver>

              <div v-show="opt_show">
                <ValidationObserver ref="step1_checking_form">
                  <ValidationProvider
                      vid="otp"
                      name="otp"
                      v-slot="{ errors }"
                      rules="required"
                  >
                    <CRow>
                      <CCol md="4">
                        <label class="text-white"
                        >Enter the code sent to
                          {{ form_params.mobile_no }}</label
                        >
                      </CCol>
                      <CCol md="4">
                        <input type="hidden" v-model="form_params.otp" />
                        <!-- <v-otp-input
                          inputClasses="otp-input"
                          :numInputs="4"
                          separator="-"
                          :shouldAutoFocus="true"
                          @on-complete="handleOnCompleteRegisterCmp"
                        /> -->
                        <br />

                        <input
                            class="text-center otp-txt-box"
                            type="text"
                            v-mask="'#-#-#-#'"
                            v-model="otp_reservation_mask"
                        />
                        <div v-if="errors[0]" class="wizard-error">
                          <span
                              v-for="(error, index) in errors"
                              v-bind:key="index"
                          >
                            {{ error }}
                          </span>
                        </div>
                      </CCol>
                      <CCol md="4">
                        <div class="text-white text-center" v-show="opt_show">
                          Time Remaining - {{ prettyTime }}
                        </div>
                        <div class="text-center">
                          <a
                              v-show="opt_show"
                              class="location-color"
                              href="#"
                              @click="resendOtp"
                          >Resend otp</a
                          >
                        </div>
                      </CCol>
                    </CRow>
                  </ValidationProvider>
                </ValidationObserver>
              </div>
            </tab-content>
            <tab-content
                title="Additional Info"
                :before-change="checkInfoValidity"
            >
              <ValidationObserver ref="step2_form">
                <div class="form-group">
                  <ValidationProvider
                      vid="email"
                      name="email"
                      v-slot="{ errors }"
                      rules=""
                  >
                    <label class="sign-up-lable">Enter your Email</label>
                    <CInput
                        size="lg"
                        placeholder="Email"
                        v-model="form_params.email"
                        :is-valid="checkValidInput(errors)"
                    />
                    <div v-if="errors[0]" class="wizard-error">
                      <span v-for="(error, index) in errors" v-bind:key="index">
                        {{ error }}
                      </span>
                    </div>
                  </ValidationProvider>
                </div>
              </ValidationObserver>
            </tab-content>

            <template slot="footer" scope="props">
              <div class="wizard-footer-left">
                <!-- remove previous button -->
                <!-- <wizard-button v-if="props.activeTabIndex > 0 && !props.isLastStep" @click.native="props.prevTab()" :style="props.fillButtonStyle">Previous</wizard-button> -->
              </div>
              <div class="wizard-footer-right" v-show="!isTimerRunning">
                <wizard-button
                    @click.native="props.nextTab()"
                    class="wizard-footer-right finish-button"
                    :style="props.fillButtonStyle"
                >{{ props.isLastStep ? "Done" : "Next" }}</wizard-button
                >
              </div>
            </template>
          </form-wizard>
        </div>
        <div
            v-if="
            check_table_availability == false &&
            check_table_availability_finish == true &&
            !reservation_id
          "
        >
          <CAlert color="warning"
          >Sorry.This table is not available at this time.</CAlert
          >
        </div>
      </div>

      <CRow v-if="reservation_id">
        <CCol class="mt-3">
          <CAlert color="warning"
          >Your reservation is processing and the Reservation PIN will be sent
            shortly.</CAlert
          >
        </CCol>
      </CRow>

      <template #header>
        <h6 class="modal-title">Do you have reservation number ?</h6>
      </template>
      <template #footer>
        <label></label>
      </template>
    </CModal>

    <CModal
        title="Please Rate Us"
        :show.sync="orderRatingModal"
        size="md"
        :closeOnBackdrop="false"
    >
      <CRow class="text-center">
        <CCol md="12">
          <p class="text-white">
            Please help us in bettering our service and website.
          </p>
          <star-rating
              :show-rating="show_rating"
              active-color="#3f2252"
              v-model="rating"
          ></star-rating>
        </CCol>
      </CRow>
      <template #footer>
        <CButton
            @click="
            orderRatingModal = false;
            rateRestaurant();
          "
            variant="outline"
            color="primary"
        >Submit</CButton
        >
        <CButton
            @click="
            orderRatingModal = false;
            redirectToMyOrders();
          "
            variant="outline"
            color="dark"
        >Not Now</CButton
        >
      </template>
      <template #header>
        <h6 class="text-white text-center text-rating-bold">
          Please rate the qulaity of our restaurant and services.
        </h6>
      </template>
    </CModal>

    <CModal
        :show.sync="cartModal"
        :no-close-on-backdrop="true"
        :centered="true"
        title="Cart"
        size="lg"
        color="dark"
        addContentClasses="mr-3"
    >
      <TheHeaderDropdownCartNew />
    </CModal>


  </div>
</template>

<script>
import RestaurantMenuCard from "@/component/RestaurantMenuCard/RestaurantMenuCard.vue";
import { mapActions, mapState, mapGetters } from "vuex";
import commonMixin from "@/mixins/common";
import store from "../../store/store";
import StarRating from "vue-star-rating";
import Echo from "laravel-echo";
import TheHeaderDropdownCartNew from "@/containers/TheHeaderDropdownCartNew.vue";
import { ref } from 'vue'
import TheHeaderDropdownCart from "@/containers/TheHeaderDropdownCart.vue";

window.Pusher = require("pusher-js");
export default {
  name: "RestaurantMenuView",
  components: {
    TheHeaderDropdownCart,
    TheHeaderDropdownCartNew,
    RestaurantMenuCard,
    StarRating,
  },
  mixins: [commonMixin],
  methods: {
    ...mapActions(["getMyOrderCart"]),
    ...mapActions("restaurant", [
      "getRestaurantData",
      "getMenuCategoryArray",
      "filterMenuItems",
    ]),
    ...mapActions("user", [
      "checkReservationNumberValidity",
      "genereateCustomerOtp",
      "checkOtpValidity",
      "checkCustomerInfoValidity",
      "checkCustomerMobile",
      "generateCustomerToken",
      "makeInstantReservation",
      "refreshToken",
      "genereateCustomerValidateOtp",
      "makeInstantRegisteredReservation",
      "genereateCustomerRegisterOtp",
      "checkTableAvailability",
      "resendOtpRequest",
    ]),
    ...mapActions("order", [
      "confirmCustomerOrderItem",
      "createRestaurantRating",
      "createCallWaiterRequest",
      "checkPendingSOSStatus",
      "setSOSStatus",
      "checkActiveReservation",
      "checkReservationHost",
    ]),
    ...mapActions("settings", ["areaList"]),
    resendOtp() {
      const params = {
        mobile_no: this.form_params.mobile_no,
      };
      this.resendOtpRequest(params).then((data) => {
        if (!data.data.error) {
          this.time = 120;
          this.start();
        } else {
        }
      });
    },

    toggleSearchInput() {
      this.showSearchInput = !this.showSearchInput;
    },
    onSearchInputChange() {
      if (this.searchQuery.length > 3) {
        this.searchMethod();  // Call your desired method when length > 3
      }
    },

    clearSearch() {
      this.searchQuery = ''; // Clear the search input field
      this.toggleSearchInput();
      this.searchMethod();
    },

    searchMethod() {
      const meal_type = 1;
      const params = {
        tenant_id: this.tenant_id,
        meal_type: meal_type,
        searchParam: this.searchQuery,
      };
      this.filterMenuItems(params).then((data) => {
        if (!data.data.error) {
          this.restaurantMenuCategory = data.data;
          this.restaurantMenuCategory1 = data.data;

          if(this.restaurantMenuCategory[0]["id"]){
            this.$store.state.activeMenuCategoryId =
                this.restaurantMenuCategory[0]["id"];
          }

        }
        this.isLoading = false;
      });
    },


    toggleVisibility() {
      this.visible = !this.visible;
    },
    toggleShowAll(index) {
      this.$set(this.showAllItems, index, !this.showAllItems[index]);
    },
    toggleRows(variationId) {
      // Toggle the expanded/collapsed state for the clicked variation
      this.$set(this.showAllRows, variationId, !this.showAllRows[variationId]);
    },


    // Show all items or only the first 3, based on 'See All' state for that category
    visibleItems(categoryItem, index) {
      return this.showAllItems[index]
          ? categoryItem.menu_items
          : categoryItem.menu_items.slice(0, 3);
    },
    visibleItemsAll(categoryItem, index) {
      return this.showAllItems[index]
          ? categoryItem.menu_items
          : categoryItem.menu_items;
    },
    visibleItems1(variation, variationIndex) {
      console.log("ffff" , variation)
      // Return only the first two items if collapsed
      if (!this.showAllRows[variation.id]) {
        return variation.attribute_values.slice(0, 2);
      }
      return variation.attribute_values; // Show all items if expanded
    },
    visibleItems2(variation, variationIndex) {
      // Return only the first two items if collapsed
      if (!this.showAllRows[variation.id]) {
        return variation.attribute_values;
      }
      return variation.attribute_values; // Show all items if expanded
    },

    rateRestaurant() {
      const params = {
        rating: this.rating,
        tenant_id: this.tenant_id,
      };
      this.createRestaurantRating(params).then((data) => {
        this.redirectToMyOrders();
      });
    },
    redirectToMyOrders() {
      this.$router.push({
        name: "MyOrders",
      });
    },
    accountExists(value) {
      if (value) {
        this.customerAccountExist = "Yes";
      } else {
        this.customerAccountExist = "No";
      }
    },
    checkOrderModalShow(varr) {
      this.foodDetailsModal = false
      if (varr == true) {
        this.showVar = true;
      } else {
        this.showVar = false;
      }
      this.showOrderModal = true;
      const params = {
        active_reservation_id: this.active_reservation_id,
      };
      this.checkActiveReservation(params).then((data) => {
        if (!data.data.error) {
          this.refreshToken().then((data) => {
            if (
                this.hasPermission("CUSTOMER_ORDER") &&
                this.hasPermission(this.tenant_id)
            ) {
            } else {
              this.$snotify
                  .warning("Your ordering session is expired.", "", {
                    position: "centerTop",
                  })
                  .on("destroyed", () => {});

              this.$router.push({
                path: "/restaurant-menu-view/" + this.tenant_id + "",
              });
            }
          });
        } else {
          this.$snotify
              .warning("Your ordering session is expired.", "", {
                position: "centerTop",
              })
              .on("destroyed", () => {});
          /* clear active reservation data */
          store.commit("user/clearActiveReservationData");
          if (!this.active_reservation_id) {
            store.commit("clearMenuCart");
          }
        }
      });
    },
    openMenuItemModal(item) {
      this.foodDetailsModal = true;
      this.viewMenuItem = item;
    },
    openSeeAllModal(index) {
      // Set the selected category and show the modal
      this.selectedCategory = this.restaurantMenuCategory[index];
      this.showSeeAllModal = true;
    },
    selectVariant(variant, item) {
      this.selectedMenuItem = [];
      this.selectedMenuItem = item;
      this.viewMenuItem = item;
      item.price = variant.menu_item_price;
      item.discount = variant.menu_item_discount;
      item.atr_price = variant.menu_item_price;
      item.atr_discount = variant.menu_item_discount;
      this.active_variation = variant;

      console.log("ssssssssssssssss" , item)
      this.active_item_id = item.id;
      item.attribute_id = variant.id;
      variant.selected = 1;

      item.item_attributes.forEach((value, index) => {
        value.attribute_values.forEach((atr_values, index) => {
          if (atr_values.id != variant.id) {
            atr_values.selected = 0;
          } else {
            atr_values.selected = 1;
          }
        });
      });

      if (!this.cartItem) {
        this.cartItem = [];  // Initialize if undefined
      }
      variant.itemMainName = item.title;
      const existingItemIndex = this.cartItem.findIndex(cartItem => cartItem.id === variant.id);

      // If the item exists in the cart, update it, otherwise push it
      if (existingItemIndex > -1) {
        this.cartItem[existingItemIndex] = variant;
      } else {
        this.cartItem.push(variant);
      }
      for (const ss of this.cartItem) {
        this.fullTotal = this.fullTotal + ss.menu_item_price;
      }
      console.log("Updated cartItem: ", item);
    },
    genereateOtp() {
      //this.genereateValidateOtp();
      if (this.customerAccountExist == "Yes") {
        this.genereateLoginOtp();
      } else {
        this.genereateRegisterOtp();
      }
    },
    genereateValidateOtp() {
      const params = {
        mobile_no: this.form_params.mobile_no,
        first_name: this.first_name,
        last_name: this.last_name,
      };
      this.genereateCustomerValidateOtp(params).then((data) => {
        if (!data.data.error) {
          this.opt_show = true;
          this.mobile_show = false;
          this.start();
        } else {
          this.$refs.step1_form.setErrors(data.data.error);
        }
      });
    },
    genereateRegisterOtp() {
      const params = {
        mobile_no: this.form_params.mobile_no,
        account_exists: this.customerAccountExist,
        first_name: this.first_name,
        last_name: this.last_name,
      };
      this.genereateCustomerRegisterOtp(params).then((data) => {
        if (!data.data.error) {
          this.opt_show = true;
          this.mobile_show = false;
          this.start();
        } else {
          this.$refs.step1_form.setErrors(data.data.error);
        }
      });
    },
    genereateLoginOtp() {
      const params = {
        mobile_no: this.form_params.mobile_no,
        account_exists: this.customerAccountExist,
      };
      this.checkCustomerMobile(params).then((data) => {
        if (!data.data.error) {
          this.opt_show = true;
          this.mobile_show = false;
          this.start();
        } else {
          this.$refs.step1_form.setErrors(data.data.error);
        }
      });
    },
    checkInfoValidity() {
      const params = {
        mobile_no: this.form_params.mobile_no,
        email: this.form_params.email,
        first_name: this.form_params.first_name,
        last_name: this.form_params.last_name,
        refresh_required: false,
      };
      this.checkCustomerInfoValidity(params).then((data) => {
        if (!data.data.error) {
        } else {
          this.$refs.step2_form.setErrors(data.data.error);
        }
      });
    },
    makeNewReservation() {
      const params = {
        mobile_no: this.form_params.mobile_no,
        tenant_id: this.tenant_id,
        table_no: this.$route.query.table_no,
      };
      this.makeInstantRegisteredReservation(params).then((data) => {
        if (!data.data.error) {
          this.$router.push({
            query: Object.assign({}, this.$route.query, {
              reservation_id: data.data.data.active_reservation_id,
            }),
          });
          this.reservation_id = data.data.data.active_reservation_id;
          this.radioValidate = "Yes";
        } else {
          this.$refs.step1_form.setErrors(data.data.error);
        }
      });
    },
    handleOnCompleteRegisterCmp(value) {
      this.form_params.otp = value;
      const params = {
        mobile_no: this.form_params.mobile_no,
        otp: this.form_params.otp,
      };
      this.checkOtpValidity(params).then((data) => {
        if (!data.data.error) {
          this.stop();
          this.vm.isTimerRunning = false;
          clearInterval(this.timer);
          this.vm.timer = null;
          const params = {
            mobile_no: this.form_params.mobile_no,
            tenant_id: this.tenant_id,
            table_no: this.$route.query.table_no,
            email: this.form_params.email,
            first_name: this.first_name,
            last_name: this.last_name,
          };
          this.makeInstantReservation(params).then((data) => {
            if (!data.data.error) {
              this.$router.push({
                query: Object.assign({}, this.$route.query, {
                  reservation_id: data.data.data.active_reservation_id,
                }),
              });
              this.reservation_id = data.data.data.active_reservation_id;
              this.radioValidate = "Yes";
            } else {
              this.$refs.step1_form.setErrors(data.data.error);
            }
          });
        } else {
          this.$refs.step1_checking_form.setErrors(data.data.error);
        }
      });
    },
    start() {
      this.isTimerRunning = true;
      if (!this.timer) {
        this.timer = setInterval(() => {
          if (this.time > 0) {
            this.time--;
          } else {
            clearInterval(this.timer);
            this.reset();
          }
        }, 1000);
      }
    },
    stop() {
      this.isTimerRunning = false;
      clearInterval(this.timer);
      this.timer = null;
    },
    reset() {
      this.stop();
      this.time = 0;
      this.secondes = 0;
      this.minutes = 0;
      this.isTimerRunning = false;
      this.timer = null;
    },
    setTime(payload) {
      this.time = payload.minutes * 60 + payload.secondes;
    },
    /* validate customer enter rservation number is valid */
    handleOnComplete(value) {
      this.reservation_number = value;
      const params = {
        reservation_number: value,
        tenant_id: this.tenant_id,
        table_no: this.$route.query.table_no,
      };
      this.checkReservationNumberValidity(params).then((data) => {
        if (!data.data.error) {
          this.validateReservationNumberModal = false;
          const params = {
            active_reservation_id: this.active_reservation_id,
          };
          this.initiateNotifications();
          this.getMyOrderCart(params).then((data) => {
            if (!data.data.error) {
            }
          });
          this.checkActualResrvationHost();
        } else {
          this.$refs.reservation_number_checking_form.setErrors(
              data.data.error
          );
        }
      });
    },
    reservationChecked(val) {
      this.radioValidate = val;
    },
    addTocart() {
      let orderItem = {
        item_id: this.selectedMenuItem.id,
        item_name: this.selectedMenuItem.title,
        count: this.menuItemCount,
        item_amount: this.selectedMenuItem.price,
        total_amount: this.selectedMenuItem.price * this.menuItemCount,
        amount: this.selectedMenuItem.price,
        item_discount: this.selectedMenuItem.discount,
        order_comment: this.order_comment,
        attribute_id: this.selectedMenuItem.attribute_id,
      };
      this.$store.state.menuItemCart.items.push(orderItem);

      let order_total = Object.values(
          this.$store.state.menuItemCart.items
      ).reduce((a, { total_amount }) => {
        return a + total_amount;
      }, 0);
      let tenant_id = this.$route.params.tenant_id;
      this.tenant_id = tenant_id;
      this.$store.state.menuItemCart.tenant_id = tenant_id;
      this.$store.state.menuItemCart.order_total = order_total;

      store.commit("setMenuCart", this.$store.state.menuItemCart);

      this.selectedMenuItem = [];
      this.menuItemCount = 1;
      this.order_comment = "";
    },
    incrementMenuItemCount() {
      this.menuItemCount++;
    },
    decrementMenuItemCount() {
      if (this.menuItemCount > 1) {
        this.menuItemCount--;
      }
    },
    setMenuItem(item) {
      this.selectedMenuItem = item;
      if (
          item.item_attributes_filter &&
          item.item_attributes_filter[0].attribute_values[0]
      ) {
        if (this.active_variation && this.active_item_id == item.id) {
          this.selectVariant(this.active_variation, item);
        } else {
          this.selectVariant(
              item.item_attributes_filter[0].attribute_values[0],
              item
          );
        }
      }
    },
    confirmOrderItem() {
      let orderItem = {
        item_id: this.selectedMenuItem.id,
        item_name: this.selectedMenuItem.title,
        count: this.menuItemCount,
        item_amount: this.selectedMenuItem.price,
        total_amount: this.selectedMenuItem.price * this.menuItemCount,
        amount: this.selectedMenuItem.price,
        item_discount: this.selectedMenuItem.discount,
        order_comment: this.order_comment,
        attribute_id: this.selectedMenuItem.attribute_id,
        active_reservation_id: this.active_reservation_id,
      };
      this.confirmCustomerOrderItem(orderItem).then((data) => {
        if (!data.data.error) {
          this.refreshOrderCart();
        } else {
        }
        this.showOrderModal = false;
        this.menuItemCount = 1;
      });
    },
    mapRedirect(restaurant) {
      let location = JSON.parse(restaurant.location);
      let route = this.$router.resolve({
        path: "/pages/map?lat=" + location.lat + "&lng=" + location.lng + "",
      });
      window.open(route.href, "_blank");
    },
    selectMealByTime() {
      const currentHour = new Date().getHours();

      // Define time ranges for each meal
      if (currentHour >= 6 && currentHour < 11) {
        this.selectedMeal = 1; // Breakfast
      } else if (currentHour >= 11 && currentHour < 15) {
        this.selectedMeal = 2; // Lunch
      } else if (currentHour >= 15 && currentHour < 18) {
        this.selectedMeal = 3; // Evening
      } else if (currentHour >= 18 && currentHour < 23) {
        this.selectedMeal = 4; // Dinner
      } else {
        this.selectedMeal = 5; // Default Meal
      }
      // this.setActivebtn(this.selectedMeal); // set timeout
    },
    setActivebtn(meal_type) {
      this.isLoading = true;
      this.$store.state.activeMealTypeBtn = meal_type;
      this.selectedMeal = meal_type;
      const params = {
        meal_type: meal_type,
        tenant_id: this.tenant_id,
        searchParam: this.searchQuery,
      };
      this.filterMenuItems(params).then((data) => {
        if (!data.data.error) {
          this.restaurantMenuCategory = data.data;
          this.restaurantMenuCategory1 = data.data;

          if(this.restaurantMenuCategory[0]["id"]){
            this.$store.state.activeMenuCategoryId =
                this.restaurantMenuCategory[0]["id"];
          }

        }
        this.isLoading = false;
      });
      //this.$store.state.activeMenuCategoryId = "";
    },
    getDynamicColor(meal_type) {
      if (this.$store.state.activeMealTypeBtn == meal_type) {
        return "primary";
      } else {
        return "light";
      }
    },
    getDynamicCategoryBtnColor(categoryId) {
      if (this.selectCatId == categoryId) {
        return "primary";
      } else {
        return "light";
      }
    },
    // setActiveMenuCategoryBtn(categoryId) {
    //   const matchedIndex = this.restaurantMenuCategory.findIndex(item => item.id === categoryId);
    //   this.selectCatId = categoryId;
    //   if (matchedIndex !== -1) {
    //     const matchedItem = this.restaurantMenuCategory[matchedIndex];
    //     this.restaurantMenuCategory.splice(matchedIndex, 1);
    //     this.restaurantMenuCategory.unshift(matchedItem);
    //     // this.$store.state.activeMenuCategoryId = categoryId;
    //     // this.getDynamicCategoryBtnColor(categoryId)
    //   }
    // },

    setActiveMenuCategoryBtn(categoryId) {
      const matchedIndex = this.restaurantMenuCategory.findIndex(item => item.id === categoryId);
      this.selectCatId = categoryId;

      if (matchedIndex !== -1) {
        // Scroll to the selected category
        this.$nextTick(() => {
          const categoryElement = document.getElementById(`category-${categoryId}`);
          if (categoryElement) {
            const elementPosition = categoryElement.getBoundingClientRect().top + window.scrollY; // Get the element's position relative to the document
            const offsetPosition = elementPosition - 100; // Adjust by 50px

            window.scrollTo({
              top: offsetPosition,
              behavior: 'smooth'
            });
          }
        });
      }
    },


    getDynamimcCategoryDIVRef(categoryId) {
      return "category_div_" + categoryId;
    },
    checkValidInput(error) {
      if (error.length) {
        return false;
      }
    },
    refreshOrderCart() {
      if (this.active_reservation_id) {
        const params = {
          active_reservation_id: this.active_reservation_id,
        };

        this.getMyOrderCart(params).then((data) => {
          if (!data.data.error) {
          }
        });
      }
    },

    //waiter reuquest for the active table reservation
    async callWaiter() {
      let data = {
        tenant_id: this.tenant_id,
      };
      await this.createCallWaiterRequest(data); // send SOS request to waiter
    },

    //enable sos button is no pending requests
    async checkSOSStatus() {
      let request_data = {
        tenant_id: this.tenant_id,
        customer_id: this.customer_id,
      };
      this.checkPendingSOSStatus(request_data);
    },

    initiateNotifications() {
      var that = this;
      if (localStorage.getItem("customer_access_token")) {
        window.Echo = new Echo({
          broadcaster: process.env.VUE_APP_WEBSOCKETS_BROADCASTER,
          key: process.env.VUE_APP_WEBSOCKETS_KEY,
          wsHost: process.env.VUE_APP_WEBSOCKETS_SERVER,
          wsPort: process.env.VUE_APP_WEBSOCKETS_PORT,
          cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
          forceTLS: false,
          disableStats: true,
          authEndpoint:
              "" + process.env.VUE_APP_API_BASE_URL + "broadcasting/auth",
        });
        window.Echo.connector.options.auth.headers[
            "Authorization"
            ] = `Bearer ${localStorage.getItem("customer_access_token")}`;
      }

      window.Echo.private(`order_closed.${that.user_id}`) // *
          .listen("OrderClose", function (e) {
            store.commit("user/clearActiveReservationData");
            if (!that.active_reservation_id) {
              store.commit("clearMenuCart");
            }
            that.$snotify
                .success("Your Order Closed", "Success!", {
                  position: "centerTop",
                })
                .on("destroyed", () => {
                  that.refreshToken().then((data) => {
                    that.orderRatingModal = true;
                    if (!data.data.error) {
                    }
                  });
                });
          });

      window.Echo.private(`order_status_changed.${that.user_id}`) // *
          .listen("OrderStatusChange", function (e) {
            if (that.active_reservation_id) {
              const params = {
                active_reservation_id: that.active_reservation_id,
              };
              that.getMyOrderCart(params).then((data) => {
                if (!data.data.error) {
                }
              });
            }
          });

      window.Echo.private(`order_add_by_waiter.${that.user_id}`) // *
          .listen("OrderAddByWaiter", function (e) {
            if (that.active_reservation_id) {
              const params = {
                active_reservation_id: that.active_reservation_id,
              };
              that.getMyOrderCart(params).then((data) => {
                if (!data.data.error) {
                }
              });
            }
          });
    },

    checkActualResrvationHost() {
      const resParams = {
        active_reservation_id: this.active_reservation_id,
      };
      this.checkReservationHost(resParams).then((data) => {
        if (!data.data.error) {
          this.isReservationHost = true;
        } else {
        }
      });
    },
  },
  watch: {
    otp_mask: {
      handler: function (after, before) {
        let otpStr = this.otp_mask;
        if (otpStr.length == 7) {
          otpStr = otpStr.replace(/-/g, "");
          this.handleOnComplete(otpStr);
        }
      },
      deep: true,
    },
    otp_reservation_mask: {
      handler: function (after, before) {
        let otpStr = this.otp_reservation_mask;
        if (otpStr.length == 7) {
          otpStr = otpStr.replace(/-/g, "");
          this.handleOnCompleteRegisterCmp(otpStr);
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.vm = this;
    let tenant_id = this.$route.params.tenant_id;
    this.reservation_id = this.$route.query.reservation_id;

    if (this.customer_access_token && this.active_reservation_id) {
      this.setSOSStatus(); //enable sos button is no pending requests
    }

    let token_validate = this.$route.query.token_validate;
    if (
        (token_validate && !this.hasPermission("CUSTOMER_ORDER")) ||
        (token_validate && !this.active_reservation_id)
    ) {
      this.validateReservationNumberModal = true;
    }

    this.tenant_id = tenant_id;
    const params = {
      tenant_id: tenant_id,
      meal_type: "all",
    };

    this.getRestaurantData(params).then((data) => {
      if (!data.data.error) {
        this.restaurant_name = data.data.restaurant_name;
        this.menuItems = data.data.menu_items;
        this.menuSliders = data.data.image_collection;
        this.restaurantData = data.data;
        this.menuBackgroundImage = data.data.menu_back_image_collection[0].url;
        // this.menuBackgroundImage = "../../assets/icons/backgr.jpeg";
        this.$store.state.service_charge = data.data.service_charge;
        store.commit("setMenuCart", this.$store.state.menuItemCart);
      }
    });

    this.getMenuCategoryArray(params).then((data) => {
      if (!data.data.error) {
        this.options = data.data;
      }
    });

    if (this.customer_access_token) {
      this.refreshOrderCart();
    }

    this.filterMenuItems(params).then((data) => {
      if (!data.data.error) {
        this.restaurantMenuCategory = data.data;
        if (this.restaurantMenuCategory[0]["id"]) {
          this.$store.state.activeMenuCategoryId =
              this.restaurantMenuCategory[0]["id"];
        }
      }
    });

    const table_params = {
      tenant_id: tenant_id,
      table_no: this.$route.query.table_no,
    };
    this.checkTableAvailability(table_params).then((data) => {
      if (!data.data.error) {
        this.check_table_availability = data.data;
      }
      this.check_table_availability_finish = true;
    });

    if (this.customer_access_token && this.active_reservation_id) {
      let request_data = {
        tenant_id: this.tenant_id,
        customer_id: this.customer_id,
      };

      this.checkPendingSOSStatus(request_data);
    }

    if (this.customer_access_token && this.active_reservation_id) {
      //check reservation host
      this.checkActualResrvationHost();
    }
    this.item.item.item_attributes.forEach(variation => {
      this.$set(this.showAllRows, variation.id, false); // Start with all variations collapsed
    });
  },
  data() {
    return {
      selectedMeal: 5, // Store the numeric value of the selected meal
      mealMap: {
        1: "Breakfast",
        2: "Lunch",
        3: "Evening",
        4: "Dinner",
        5: "Meal",
      },
      showSearchInput: false,
      searchQuery: '',
      headerDes: '',
      visible: false,
      value: null,
      options: [
        {
          id: "a",
          label: "a",
          children: [
            {
              id: "aa",
              label: "aa",
            },
            {
              id: "ab",
              label: "ab",
            },
          ],
        },
        {
          id: "b",
          label: "b",
        },
        {
          id: "c",
          label: "c",
        },
      ],
      restaurant_name: "",
      menuItems: [],
      menuSliders: [],
      menuBackgroundImage: "",
      menuBackgroundImage1: require('../../assets/icons/backgr.jpeg'),
      restaurantData: [],
      tenant_id: "",
      restaurantMenuCategory: [],
      selectCatId: '',
      restaurantMenuCategory1: [],
      showAllItems: {},
      isLoading: false,
      fullPage: true,
      showOrderModal: false,
      showSeeAllModal: false,
      selectedCategory: null,
      selectedMenuItem: [],
      showVar: true,
      showAllRows: {},
      menuItemCount: 1,
      menuItemCart: [],
      order_comment: "",
      validateReservationNumberModal: false,
      cartModal: false,
      cartItem: [],
      fullTotal: 0,
      reservation_no_have: "",
      radioValidate: "Yes",
      reservation_number: "",
      customerAccountExist: "Yes",
      otp_mask: "",
      otp_reservation_mask: "",
      form_params: {
        mobile_no: "",
        first_name: "",
        last_name: "",
        email: "",
        otp: "",
      },
      opt_show: false,
      mobile_show: true,
      vm: "",
      isTimerRunning: false,
      minutes: 2,
      secondes: 0,
      time: 120,
      timer: null,
      reservation_id: "",
      active_variation: "",
      active_item_id: "",
      account_exists: true,
      foodDetailsModal: false,
      viewMenuItem: { image_collection: [],},
      first_name: "",
      last_name: "",
      customer_id: localStorage.getItem("user_id"),
      rating: 0,
      show_rating: false,
      check_table_availability: false,
      check_table_availability_finish: false,
      isReservationHost: false,
    };
  },
  computed: {
    ...mapState("user", [
      "user_name",
      "customer_access_token",
      "active_reservation_id",
      "user_id",
    ]),
    ...mapState("user", ["user_name", "customer_access_token","active_reservation_id"]),
    ...mapState("order", ["SOSDisable"]),
    ...mapGetters("user", ["loggedIn"]),
    orderRatingModal: {
      get() {
        return this.$store.state.orderRatingModal;
      },
      set(value) {
        this.$store.state.orderRatingModal = value;
      },
    },
    prettyTime() {
      let time = this.time / 60;
      let minutes = parseInt(time);
      let secondes = Math.round((time - minutes) * 60);
      return minutes + ":" + secondes;
    },
  },
  created() {
    this.initiateNotifications();
    this.selectMealByTime();
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@500&display=swap");
.min-height-desc {
  min-height: 30px;
}

.modal-dark .modal-content {
  border-color: #636f83;
  margin-top: 60px !important;
}
.modal-content {
  margin-top: 60px;
}
.search-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0px;
  width: 100%;
  max-width: 400px;
  margin: auto;

}

.search-input {
  flex: 1;
  padding: 8px 12px;
  font-size: 12px;
  border: 1px solid #ccc;
  border-radius: 4px 0 0 4px;
  outline: none;
  height: 25px;
}

.search-input:focus {
  border-color: blueviolet;
}

.search-button {
  padding: 8px 8px;
  background-color:blueviolet;
  color: #fff;
  border: none;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
}

.search-button:hover {
  background-color: #0056b3;
}

.search-button svg {
  vertical-align: middle;
}
.custom-modal {
  margin: 60px auto; /* Adds top and bottom margin of 60px */
  max-height: calc(100vh - 120px); /* Ensures the modal fits within the viewport */
  overflow: hidden; /* Prevents content overflow */
}

.custom-modal .modal-body {
  max-height: calc(100vh - 200px); /* Adjust the body height to fit within the modal */
  overflow-y: auto; /* Allow vertical scrolling */
  padding: 0; /* Remove padding from the modal body */
}

.custom-modal .modal-header,
.custom-modal .modal-footer {
  position: sticky;
  z-index: 10;
}



.hidden-rows {
  display: none;
}
.btn-outline-primary1 {
  border: 2px solid blueviolet;
  color: blueviolet;
  background-color: transparent;
  padding: 0px 10px;
  font-size: 12px;
  border-radius: 4px;
  transition: box-shadow 0.3s ease;
}

.btn-outline-primary1:hover {
  box-shadow: 0 0 10px blueviolet;
  border-color: blueviolet;
}
.show-more {
  background: white;
  border: blueviolet;
  color: blueviolet;
  cursor: pointer;
  font-size: 12px;
}

.card-content {
  display: flex;
  align-items: center;   /* Align image and content vertically centered */
}
/* Overall Card Styling */
.shadow-card {
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  border-radius: 12px; /* Rounded corners */
  border: none; /* No borders */
  padding-left: 1rem; /* Padding inside the card */
  margin-bottom: 1rem; /* Spacing between cards */
}
.small-card {
  border-radius: 4px;            /* Make the card edges rounded */
  display: flex;
  align-items: center;           /* Center content vertically */
  justify-content: center;       /* Center content horizontally by default */
  border: 1px solid #eabfea;     /* Add a border with a light color */
  font-size: 12px;
  width: 80px;                   /* Set the width of the card */
  height: 20px;                  /* Set the height of the card */
}

.truncate-text {
  display: inline-block;         /* Allow the text to behave like inline content */
  overflow: hidden;              /* Hide overflowing text */
  text-overflow: ellipsis;       /* Add ellipsis when text overflows */
  white-space: nowrap;           /* Prevent text from wrapping */
  text-align: center;            /* Center text when it fits within the card */
  width: 100%;                   /* Ensure it takes up the full width of the parent */
}

.small-card:hover .truncate-text {
  text-align: left;              /* Left-align text when it overflows */
}



.icon-box {
  display: inline-block;
  width: 18px; /* Set box size */
  height: 18px;
  background-color: rgba(182, 173, 173, 0.2); /* Blue background */
  border-radius: 2px; /* Optional: small rounding */
  text-align: center;

}

.icon-box i {
  font-size: 12px; /* Small icon size */
  color: black; /* Icon color */
  padding: 2px;
}



/* Category Title Styling */
.category-title {
  font-weight: bold;
  font-size: 1.2rem;
}

/* Fixed Card Height */
.fixed-height-card {
  height: 150px; /* Fixed card height */
  display: flex;
  align-items: center; /* Center the content */
}

/* Image Container */
.card-image-container {
  padding: 0; /* No padding */
}

.card-image {
  width: 100%; /* Full width of the column (25% of card) */
  height: 100%; /* Full height of the card */
  object-fit: cover; /* Maintain aspect ratio while filling the container */
  border-radius: 8px; /* Slightly rounded edges */
}

/* Text Content */
.card-text-content {
  padding-left: 2px; /* Padding to create space between image and text */
}

.price-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Item Price Styling */
.item-price {
  font-weight: bold;
  color: #333;
}

/* Add to Cart Button */
.add-to-cart-btn {
  background-color: #28a745; /* Green button */
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  cursor: pointer;
}

/* See All Button Styling */
.text-button {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;
}

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow-x: hidden; /* Prevent horizontal scroll */
}

.cart-button-container {
  position: fixed;
  bottom: -5px;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1000; /* Ensure it's above other elements */
  background-color: transparent;
  padding: 10px 10px 0px 0px;
  //box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
  display: flex;
  justify-content: center; /* Centers the button horizontally */
}


.view-cart-button {
  width: auto; /* Full width for the button itself */
  //height: 36px;
  padding: 0px 0px 15px 0px;
}




/* Image Styling for Full Height and 25% Width */
.card-image {
  width: 100%; /* Takes full width of the col-md-3 (25%) */
  height: 100%; /* Ensures image takes full height of the card */
  object-fit: cover; /* Ensures the image is fully contained within the area */
  border-radius: 8px 0 0 8px; /* Match card corner radius for seamless design */
}

/* Text content alignment */
.card-text-content {
  padding-left: 15px; /* Adjust padding inside the text content */
}

/* Button Styling */
.text-button {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;
}

.card-image {
  width: 25%;            /* Make the image occupy 25% of the card width */
  aspect-ratio: 1 / 1;   /* Ensure a 1:1 aspect ratio */
  object-fit: cover;     /* Ensure the image covers its container */
}

.card-text-content {
  padding-left: 10px;    /* Add some padding between the image and text */
  flex-grow: 1;          /* Allow the text content to take the remaining space */
}

.menu-header {
  //background-color: rgb(49, 52, 63);
  height: 50px; /* Adjust the height to your preference */
  width: 102%;
}

.font-family {
  font-family: "Dancing Script", cursive;
}

.rating-arrangment {
  float: right;
}

.restaurant-img {
  object-fit: cover;
  width: 100%;
  height: 150px;
  border-radius: 0rem 0rem 0rem 0rem !important;
}

.card-title,
.location-text,
.address-text,
.description-text,
.min-height-desc,
.discount-text {
  color: #ffffff;
}

.category-color {
  background-color: rgba(255, 255, 255, 0.493);
  color: #ffffff;
  padding: 0.5rem 1.25rem;
}

.location-color {
  color: blueviolet;
}

.rest-bg-imge {
  /* background-image: url(“/img/bg_images/rest-img.jpg”); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 90vh;
}

.resturent-main-card {
  background-color: rgba(0, 0, 0, 0.514);
  min-height: 90vh;
}

/* .food-item-scroll {
  max-height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
} */

.scrolling-wrapper {
  align-items: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: scroll;
}
.card-item-new {
  max-width: 100%;
  padding: 0rem;
  /* margin-bottom: 2rem; */
  border: 0;
  flex-basis: 25%;
  flex-grow: 0;
  flex-shrink: 0;
}
.card-item {
  max-width: 25%;
  padding: 0.75rem;
  /* margin-bottom: 2rem; */
  border: 0;
  flex-basis: 25%;
  flex-grow: 0;
  flex-shrink: 0;
  min-height: 330px;
}

.rating-arrangment {
  float: right;
}

.min-height-card {
  min-height: 250px;
  max-height: 250px;
  border-radius: 1.5rem !important;
  /* border: 0.1rem solid #FFFFFF ; */
  background-color: rgba(0, 0, 0, 0.822);
  padding-bottom: 0.8rem;
  box-shadow: rgb(28 28 28 / 12%) 0px 0.4rem 1.8rem;
  min-width: 23vw;
}

.empty-food-row {
  min-height: 250px;
  max-height: 250px;
}

.card-border {
  /* border: 1px solid #FFFFFF !important; */
}

hr.dashed {
  border-top: 0.12rem dashed #bbb;
  margin-bottom: 4px;
  margin-top: -5px;
}
.mobile-space{
  min-height: 0px;
  max-height: 0px;
}

@media (max-width: 575.98px) {
  /* .food-row{
    padding-bottom: 100px;
  } */
  .mobile-space{
    min-height: 250px;
    max-height: 250px;
  }
  .min-height-card {
    margin-bottom: 1px !important;
  }
  .scrolling-wrapper {
    flex-direction: column !important;
    max-height: 700px;
  }
  .card-item-new {
    max-width: 100%;
    padding: 0.75rem;
    /* margin-bottom: 2rem; */
    border: 0;
    flex-basis: 25%;
    flex-grow: 0;
    flex-shrink: 0;
  }
  .card-item {
    max-width: 100%;
    padding: 0.75rem;
    /* margin-bottom: 2rem; */
    border: 0;
    flex-basis: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    min-height: 330px;
  }
  .mobile-view {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    width: auto;
  }
  .menu-header {
    //background-color: rgb(49, 52, 63);
  }
}
.mobile-view {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}
@media (max-width: 768px) {
  .mobile-space{
    min-height: 250px;
    max-height: 250px;
  }
  .tree-select {
    padding-top: 20px;
  }

  .meal-plan {
    //display: flex;
    //overflow-x: scroll; /* Keep horizontal scrolling */
    //scroll-behavior: smooth;
    //position: relative; /* Ensure position context for dropdowns */
  }

}

@media (min-width: 768px) and (max-width: 991.98px) {
  .mobile-space{
    min-height: 250px;
    max-height: 250px;
  }
  .card-item-new {
    max-width: 100%;
    padding: 0.75rem;
    /* margin-bottom: 2rem; */
    border: 0;
    flex-basis: 25%;
    flex-grow: 0;
    flex-shrink: 0;
  }
  .card-item {
    max-width: 50%;
    padding: 0.75rem;
    /* margin-bottom: 2rem; */
    border: 0;
    flex-basis: 50%;
    flex-grow: 0;
    flex-shrink: 0;
    min-height: 330px;
  }
}

@media (min-width: 2500px) {
  .card-item-new {
    max-width: 100%;
    padding: 0.75rem;
    /* margin-bottom: 2rem; */
    border: 0;
    flex-basis: 25%;
    flex-grow: 0;
    flex-shrink: 0;
  }
  .card-item {
    max-width: 25%;
    padding: 0.75rem;
    /* margin-bottom: 2rem; */
    border: 0;
    flex-basis: 25%;
    flex-grow: 0;
    flex-shrink: 0;
    min-height: 330px;
  }
}
</style>
<style>
.custom-dropdown .dropdown-toggle {
  background-color: rgb(196, 190, 190); /* Set the button background to white */
  padding: 0 5px; /* Horizontal padding */
  height: 25px; /* Match the height of other buttons */
  display: flex;
  align-items: center; /* Center content vertically */
  justify-content: center; /* Center content horizontally */
  font-size: 12px; /* Adjust font size */
  white-space: nowrap; /* Prevent text from wrapping */
}

.custom-dropdown .dropdown-toggle:hover {
  background-color: #f0f0f0; /* Lighten on hover */
  color: #000;
}

.custom-dropdown .dropdown-menu {
  background-color: #333; /* Keep the dropdown items background dark */
}

.custom-dropdown .dropdown-menu .dropdown-item {
  color: #fff; /* Ensure the dropdown items text is white */
}

.custom-button {
  padding: 0 20px; /* Horizontal padding */
  height: 25px; /* Match the height of other buttons */
  display: flex;
  align-items: center; /* Center content vertically */
  justify-content: center; /* Center content horizontally */
  font-size: 16px; /* Adjust font size */
  white-space: nowrap; /* Prevent text from wrapping */
}

.custom-button .cil-phone {
  margin-left: 5px; /* Space between text and icon */
}
.emoji-button {
  border-radius: 50%; /* Make the button round */
  width: 40px; /* Set a fixed width */
  height: 40px; /* Set a fixed height */
  display: flex;
  align-items: center; /* Center emoji vertically */
  justify-content: center; /* Center emoji horizontally */
  font-size: 20px; /* Adjust font size for emojis */
  padding: 0; /* Remove default padding */
}

.emoji-button.angry {
  background-color: red; /* Red background for angry emoji */
  color: white; /* Ensure the emoji is visible on red background */
}




.custom-wizard-menu .wizard-header {
  display: none;
}

.custom-wizard-menu .category {
  font-size: 35px;
  font-weight: bold;
}

.custom-wizard-menu .wizard-card-footer .wizard-footer-right {
  float: right;
  width: 100%;
}

.finish-button {
  padding: 18px !important;
}

.wizard-progress-with-circle {
  display: none;
}

.wizard-nav.wizard-nav-pills {
  display: none;
}

.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0 14px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: "center";
}

.error {
  border: 1px solid red !important;
}

.container-fluid {
  max-width: 100%;
  padding-right: 0rem !important;
  padding-left: 0rem !important;
  margin-right: 0rem !important;
  margin-left: 0rem !important;
}

.c-main {
  padding-top: 0rem !important;
  margin-bottom: -28px !important;
  /* remove padding will display whitel layers in bottom  */
}

.modal-dark .modal-header {
  padding-bottom: 35px;
  height: 10px;
  color: #fff;
  background-color: #111111 !important;
  border: 1px solid #171718 !important;
}

.modal-body {
  padding: 0.2rem;
  background-color: #171718 !important;
  border: 1px solid #171718 !important;
}

.modal-footer {
  background-color: #171718 !important;
  border: 1px solid #171718 !important;
}

.form-control {
  background-color: #171718 !important;
  border: 1px solid #ffffff !important;
}
</style>

<style scoped>
.sub-category-btn {
  white-space: nowrap;
  text-align: center;
}

.min-height-desc {
  min-height: 30px;
}

.rating-arrangment {
  float: right;
}

.restaurant-img {
  object-fit: cover;
  width: 100%;
  height: 150px;
  border-radius: 0rem 0rem 0rem 0rem !important;
}

.card-title,
.location-text,
.address-text,
.description-text,
.min-height-desc,
.discount-text {
  color: #ffffff;
}

.location-color {
  color: blueviolet;
}

.rest-bg-imge {
  /* background-image: url("/img/bg_images/rest-img.jpg"); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.scrolling-wrapper {
  align-items: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: scroll;
}
.card-item-new {
  max-width: 100%;
  padding: 0.75rem;
  /* margin-bottom: 2rem; */
  border: 0;
  flex-basis: 25%;
  flex-grow: 0;
  flex-shrink: 0;
}

.card-item {
  max-width: 25%;
  padding: 0.75rem;
  /* margin-bottom: 2rem; */
  border: 0;
  flex-basis: 25%;
  flex-grow: 0;
  flex-shrink: 0;
  min-height: 250px;
}

.rating-arrangment {
  float: right;
}

.min-height-card {
  min-height: 250px;
  max-height: 250px;
  border-radius: 1.5rem !important;
  /* border: 0.1rem solid #ffffff ; */
  background-color: rgba(0, 0, 0, 0.822);
  padding-bottom: 0.8rem;
  box-shadow: rgb(28 28 28 / 12%) 0px 0.4rem 1.8rem;
  min-width: 23vw;
}

.resturent-main-card {
  background-color: rgba(0, 0, 0, 0.514);
}

.card-border {
  /* border: 1px solid #ffffff !important; */
}
.text-button {
  background: none;        /* Remove background color */
  border: none;            /* Remove border */
  padding: 0;              /* Remove padding */
  color: #61a8d0;          /* Set text color to light blue */
  font-size: 10px;      /* Use the inherited font size */
  cursor: pointer;         /* Show pointer on hover */
}

.text-button:hover {
  text-decoration: underline; /* Optional: underline text on hover */
  color: #1921c2;
}


hr.dashed {
  border-top: 0.12rem dashed #bbb;
  margin-bottom: 4px;
  margin-top: -5px;
}
@media (max-width: 575.98px) {
  .card-item-new {
    max-width: 100%;
    padding: 0.75rem;
    /* margin-bottom: 2rem; */
    border: 0;
    flex-basis: 25%;
    flex-grow: 0;
    flex-shrink: 0;
  }
  .card-item {
    max-width: 100%;
    padding: 0.75rem;
    /* margin-bottom: 2rem; */
    border: 0;
    flex-basis: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    min-height: 250px;
  }
  .custom-dropdown .dropdown-toggle {
    background-color: white; /* Set the button background to white */
    color: #000; /* Set the text color to black */
    border-radius: 50px; /* Make the button oval */
    padding: 5px 20px; /* Add padding for a more oval shape */
    border: 1px solid #ccc; /* Optional: Add a border */
    box-shadow: none; /* Remove any default shadow */
    height: 40px; /* Set a fixed height */
    display: flex;
    align-items: center; /* Center content vertically */
  }

  .custom-dropdown .dropdown-toggle:hover {
    background-color: #f0f0f0; /* Lighten on hover */
    color: #000;
  }

  .custom-dropdown .dropdown-menu {
    background-color: #333; /* Keep the dropdown items background dark */
  }

  .custom-dropdown .dropdown-menu .dropdown-item {
    color: #fff; /* Ensure the dropdown items text is white */
  }

  .custom-button {
    font-size: 12px; /* Smaller font size for very small screens */
    padding: 0 5px; /* Adjust padding for small screens */
  }

  .emoji-button {
    border-radius: 50%; /* Make the button round */
    width: 40px; /* Set a fixed width */
    height: 40px; /* Set a fixed height */
    display: flex;
    align-items: center; /* Center emoji vertically */
    justify-content: center; /* Center emoji horizontally */
    font-size: 20px; /* Adjust font size for emojis */
    padding: 0; /* Remove default padding */
  }

  .emoji-button.angry {
    background-color: red; /* Red background for angry emoji */
    color: white; /* Ensure the emoji is visible on red background */
  }

}

@media (max-width: 768px) {
  .tree-select {
    padding-top: 20px;
  }

  .meal-plan {
    //display: flex;
    //overflow-x: scroll; /* Keep horizontal scrolling */
    //scroll-behavior: smooth;
    //position: relative; /* Ensure position context for dropdowns */
  }

  .custom-dropdown .dropdown-toggle {
    background-color: white; /* Set the button background to white */
    color: #000; /* Set the text color to black */
    border-radius: 50px; /* Make the button oval */
    padding: 5px 20px; /* Add padding for a more oval shape */
    border: 1px solid #ccc; /* Optional: Add a border */
    box-shadow: none; /* Remove any default shadow */
    height: 40px; /* Set a fixed height */
    display: flex;
    align-items: center; /* Center content vertically */
    position: static;
  }

  .custom-dropdown .dropdown-toggle:hover {
    background-color: #f0f0f0; /* Lighten on hover */
    color: #000;
  }

  .custom-dropdown .dropdown-menu {
    background-color: #333; /* Keep the dropdown items background dark */
  }

  .custom-dropdown .dropdown-menu .dropdown-item {
    color: #fff; /* Ensure the dropdown items text is white */
  }
  .custom-dropdown-menu {
    position: absolute; /* Allows the dropdown to appear outside the parent */
    z-index: 1050; /* Ensures it appears above other elements */
  }
  .custom-button {
    font-size: 12px; /* Smaller font size for very small screens */
    padding: 0 5px; /* Adjust padding for small screens */
  }

  .emoji-button {
    border-radius: 50%; /* Make the button round */
    width: 40px; /* Set a fixed width */
    height: 40px; /* Set a fixed height */
    display: flex;
    align-items: center; /* Center emoji vertically */
    justify-content: center; /* Center emoji horizontally */
    font-size: 20px; /* Adjust font size for emojis */
    padding: 0; /* Remove default padding */
  }

  .emoji-button.angry {
    background-color: red; /* Red background for angry emoji */
    color: white; /* Ensure the emoji is visible on red background */
  }






}

@media (min-width: 768px) and (max-width: 991.98px) {
  .card-item-new {
    max-width: 100%;
    padding: 0.75rem;
    /* margin-bottom: 2rem; */
    border: 0;
    flex-basis: 25%;
    flex-grow: 0;
    flex-shrink: 0;
  }
  .card-item {
    max-width: 50%;
    padding: 0.75rem;
    /* margin-bottom: 2rem; */
    border: 0;
    flex-basis: 50%;
    flex-grow: 0;
    flex-shrink: 0;
    min-height: 250px;
  }
  .custom-dropdown .dropdown-toggle {
    background-color: white; /* Set the button background to white */
    color: #000; /* Set the text color to black */
    border-radius: 50px; /* Make the button oval */
    padding: 5px 20px; /* Add padding for a more oval shape */
    border: 1px solid #ccc; /* Optional: Add a border */
    box-shadow: none; /* Remove any default shadow */
    height: 40px; /* Set a fixed height */
    display: flex;
    align-items: center; /* Center content vertically */
  }

  .custom-dropdown .dropdown-toggle:hover {
    background-color: #f0f0f0; /* Lighten on hover */
    color: #000;
  }

  .custom-dropdown .dropdown-menu {
    background-color: #333; /* Keep the dropdown items background dark */
  }

  .custom-dropdown .dropdown-menu .dropdown-item {
    color: #fff; /* Ensure the dropdown items text is white */
  }

  .custom-button {
    font-size: 12px; /* Smaller font size for very small screens */
    padding: 0 5px; /* Adjust padding for small screens */
  }

  .emoji-button {
    border-radius: 50%; /* Make the button round */
    width: 40px; /* Set a fixed width */
    height: 40px; /* Set a fixed height */
    display: flex;
    align-items: center; /* Center emoji vertically */
    justify-content: center; /* Center emoji horizontally */
    font-size: 20px; /* Adjust font size for emojis */
    padding: 0; /* Remove default padding */
  }

  .emoji-button.angry {
    background-color: red; /* Red background for angry emoji */
    color: white; /* Ensure the emoji is visible on red background */
  }





}

@media (min-width: 2500px) {
  .card-item-new {
    max-width: 100%;
    padding: 0.75rem;
    /* margin-bottom: 2rem; */
    border: 0;
    flex-basis: 25%;
    flex-grow: 0;
    flex-shrink: 0;
  }
  .card-item {
    max-width: 25%;
    padding: 0.75rem;
    /* margin-bottom: 2rem; */
    border: 0;
    flex-basis: 25%;
    flex-grow: 0;
    flex-shrink: 0;
    min-height: 250px;
  }
  .custom-dropdown .dropdown-toggle {
    background-color: white; /* Set the button background to white */
    color: #000; /* Set the text color to black */
    border-radius: 50px; /* Make the button oval */
    padding: 5px 20px; /* Add padding for a more oval shape */
    border: 1px solid #ccc; /* Optional: Add a border */
    box-shadow: none; /* Remove any default shadow */
    height: 40px; /* Set a fixed height */
    display: flex;
    align-items: center; /* Center content vertically */
  }

  .custom-dropdown .dropdown-toggle:hover {
    background-color: #f0f0f0; /* Lighten on hover */
    color: #000;
  }

  .custom-dropdown .dropdown-menu {
    background-color: #333; /* Keep the dropdown items background dark */
  }

  .custom-dropdown .dropdown-menu .dropdown-item {
    color: #fff; /* Ensure the dropdown items text is white */
  }

  .custom-button {
    padding: 0 20px; /* Horizontal padding */
    height: 40px; /* Match the dropdown height */
    display: flex;
    align-items: center; /* Center content vertically */
    justify-content: center; /* Center content horizontally */
    font-size: 12px; /* Adjust font size */
  }

  .emoji-button {
    border-radius: 50%; /* Make the button round */
    width: 40px; /* Set a fixed width */
    height: 40px; /* Set a fixed height */
    display: flex;
    align-items: center; /* Center emoji vertically */
    justify-content: center; /* Center emoji horizontally */
    font-size: 20px; /* Adjust font size for emojis */
    padding: 0; /* Remove default padding */
  }

  .emoji-button.angry {
    background-color: red; /* Red background for angry emoji */
    color: white; /* Ensure the emoji is visible on red background */
  }




}
</style>
