<template>
  <div>
    <loading
        :active.sync="isLoading"
        :is-full-page="fullPage"
        :loader="$store.state.spinerLoader"
        :color="$store.state.spinerColor"
    ></loading>
    <vue-snotify></vue-snotify>
    <CDropdown
        class="c-header-nav-items"
        placement="bottom-end"
        add-menu-classes="pt-0"
        :show.sync="dpShow"
    >
      <template #toggler>
        <CHeaderNavLink>
          <button class="cart-btn mr-2" style="color: white;">View Cart ({{ menuItemCart.length }})</button>
        </CHeaderNavLink>
      </template>
      <CDropdownHeader tag="div" class="text-center cart-size" color="dark">
        <strong>Cart</strong>
        <CIcon name="cil-x-circle" class="text-white float-right" @click.native="closeCart()"/>
      </CDropdownHeader>

      <div class="menu-item-cart-wrapper">
        <p class="text-white ml-2 pl-2 menu-category-header mr-2">
          Billing Items
        </p>
        <CContainer
            class="cart-item-mobile"
            v-for="(menuItem, index) in filterMenuCart(1)"
        >
          <div class="pl-3 row">

            <div  class=" w-50">
              <p>{{menuItem.item_count}} - {{ menuItem.item.title | truncate(15, "...") }}</p>
            </div>
            <div class=" text-right w-25">
              <p>{{ menuItem.item_total | currency("Rs ") }}</p>
            </div>

            <div  class="w-25 pl-3">
              <span v-html="menuItem.status_label_sm"></span>
            </div>
          </div>
          <!-- <CDropdownDivider /> -->
        </CContainer>

        <p class="text-white ml-2 pl-2 menu-category-header mr-2">
          Non Billing Items
        </p>
        <CContainer
            class="cart-item-mobile"
            v-for="(menuItem, index) in filterMenuCart(0)"
        >
          <div class="pl-3 row">

            <div class=" w-50">
              <p>{{menuItem.item_count}} - {{ menuItem.item.title | truncate(15, "...") }}</p>
            </div>
            <div class=" text-right w-25">
              <p></p>
            </div>

            <div class="w-25 pl-3">
              <span v-html="menuItem.status_label_sm"></span>
            </div>
          </div>
          <!-- <CDropdownDivider /> -->
        </CContainer>
      </div>

      <CDropdownDivider />

      <div v-if="false">
        <CRow v-if="this.$store.state.menuItemCart.order_items.length != 0">
          <CCol md="12" class="ml-2"><p>Summary</p></CCol>
        </CRow>
        <CRow v-if="this.$store.state.menuItemCart.order_items.length != 0">
          <CCol md="11" class="ml-2">
            <table class="table order-summary-table">
              <tr>
                <td>Cart Total</td>
                <td class="text-right">{{ orderTotal | currency("Rs ") }}</td>
              </tr>
              <tr>
                <td>Discount</td>
                <td class="text-right">
                  {{ discountTotal | currency("Rs ") }}
                </td>
              </tr>
              <tr>
                <td>Total</td>
                <td class="text-right">{{ total | currency("Rs ") }}</td>
              </tr>
              <tr>
                <td>Service Charge ({{ restServiceCharge }} %)</td>
                <td class="text-right">
                  {{ serviceCharge | currency("Rs ") }}
                </td>
              </tr>
              <tr>
                <td><b>Sub Total</b></td>
                <td class="text-right">
                  <b>{{ subTotal | currency("Rs ") }}</b>
                </td>
              </tr>
            </table>
            <CDropdownDivider />
          </CCol>
        </CRow>

        <CDropdownItem>
          <CButton
              v-if="this.$store.state.menuItemCart.order_items.length"
              color="primary"
              size="md"
              @click="orderFinalCart"
              block
          >Order</CButton
          >
          <CButton
              v-if="this.$store.state.menuItemCart.order_items.length == 0"
              color="primary"
              size="md"
              block
          >
            <CIcon name="cil-cart" /> Cart Is Empty</CButton
          >
        </CDropdownItem>
      </div>

      <CDropdownItem>
        <CButton
            v-if="this.$store.state.menuItemCart.order_items.length"
            color="primary"
            size="md"
            @click="customerOrderClose"
            disabled="disabled"
            block
        >End Dining</CButton
        >
      </CDropdownItem>
    </CDropdown>

    <CModal
        title="Please Rate Us"
        :show.sync="orderRatingModal"
        size="md"
        :closeOnBackdrop="false"
    >
      <CRow class="text-center">
        <CCol md="12">
          <p>Please help us in bettering our service and website.</p>
          <star-rating
              :show-rating="show_rating"
              active-color="#3f2252"
              v-model="rating"
          ></star-rating>
        </CCol>
      </CRow>
      <template #footer>
        <CButton
            @click="
            orderRatingModal = false;
            rateRestaurant();
          "
            variant="outline"
            color="primary"
        >Submit</CButton
        >
        <CButton
            @click="
            orderRatingModal = false;
            redirectToMyOrders();
          "
            variant="outline"
            color="dark"
        >Cancel</CButton
        >
      </template>
      <template #header>
        <h5 class="text-white text-center text-rating-bold">
          Please rate the qulaity of our restaurant and services.
        </h5>
      </template>
    </CModal>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import store from "../store/store";
import StarRating from "vue-star-rating";
import commonMixin from "@/mixins/common";
export default {
  name: "TheHeaderDropdownCartNew",
  components: {
    StarRating,
  },
  mixins: [commonMixin],
  data() {
    return {
      itemsCount: 42,
      tenant_id: "",
      isLoading: false,
      fullPage: true,
      rating: 0,
      show_rating: false,
      dpShow: false
    };
  },
  computed: {
    ...mapState("user", ["user_id", "active_reservation_id"]),
    orderRatingModal: {
      get() {
        return this.$store.state.orderRatingModal;
      },
      set(value) {
        this.$store.state.orderRatingModal = value;
      },
    },
    menuItemCart() {
      return this.$store.state.menuItemCart.order_items;
    },
    orderTotal() {
      let order_total = Object.values(
          this.$store.state.menuItemCart.order_items
      ).reduce((a, {total_amount}) => {
        return a + total_amount;
      }, 0);

      return order_total;
    },
    discountTotal() {
      let order_total = Object.values(
          this.$store.state.menuItemCart.order_items
      ).reduce((a, {total_amount, item_discount}) => {
        return a + (total_amount * item_discount) / 100;
      }, 0);

      return order_total;
    },
    restServiceCharge() {
      return this.$store.state.service_charge;
    },
    serviceCharge() {
      return (
          ((this.orderTotal - this.discountTotal) * this.restServiceCharge) / 100
      );
    },
    total() {
      return this.orderTotal - this.discountTotal;
    },
    subTotal() {
      return this.orderTotal - this.discountTotal + this.serviceCharge;
    },
  },
  methods: {
    ...mapActions("restaurant", ["orderConfirm", "orderCloseByCustomer"]),
    ...mapActions("user", ["refreshToken"]),
    ...mapActions("order", [
      "createRestaurantRating",
      "checkActiveReservation",
    ]),
    closeCart() {
      this.dpShow = false;
    },
    rateRestaurant() {
      const params = {
        rating: this.rating,
        tenant_id: this.tenant_id,
      };
      this.createRestaurantRating(params).then((data) => {
        this.redirectToMyOrders();
      });
    },
    redirectToMyOrders() {
      this.$router.push({
        name: "MyOrders",
      });
    },
    customerOrderClose() {
      let vm = this;
      vm.$snotify.confirm(
          "Are you sure want to close your order?",
          "Confirmation",
          {
            timeout: 0,
            showProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            position: "centerTop",
            buttons: [
              {
                text: "Yes",
                action: (toast) => {
                  const params = {
                    active_reservation_id: vm.active_reservation_id,
                  };
                  vm.checkActiveReservation(params).then((data) => {
                    if (!data.data.error) {
                      vm.closeOrder();
                    } else {
                      vm.$snotify
                          .warning("Your ordering session is expired.", "", {
                            position: "centerTop",
                          })
                          .on("destroyed", () => {
                          });
                      /* clear active reservation data */
                      store.commit("user/clearActiveReservationData");
                      if (!this.active_reservation_id) {
                        store.commit("clearMenuCart");
                      }
                    }
                  });

                  vm.$snotify.remove(toast.id);
                },
                bold: false,
              },
              {
                text: "No",
                action: (toast) => {
                  vm.$snotify.remove(toast.id);
                },
              },
            ],
          }
      );
    },
    closeOrder() {
      this.refreshToken().then((data) => {
        if (
            this.hasPermission("CUSTOMER_ORDER") &&
            this.hasPermission(this.tenant_id)
        ) {
          const params = {
            order_id: this.$store.state.menuItemCart.id,
          };
          this.orderCloseByCustomer(params).then((data) => {
            if (!data.data.error) {
              /* clear active reservation data */
              store.commit("user/clearActiveReservationData");
              if (!this.active_reservation_id) {
                store.commit("clearMenuCart");
              }
              if (!data.data.error) {
                this.$snotify
                    .success(data.data.success, "", {
                      position: "centerTop",
                    })
                    .on("destroyed", () => {
                      this.orderRatingModal = true;
                    });
              }
            }
          });
        } else {
          this.$snotify
              .warning("Your ordering session is expired.", "", {
                position: "centerTop",
              })
              .on("destroyed", () => {
                /* clear active reservation data */
                store.commit("user/clearActiveReservationData");
                if (!this.active_reservation_id) {
                  store.commit("clearMenuCart");
                }
              });
        }
      });
    },
    changeMenuItemCount(menuItem) {
      menuItem.total_amount = menuItem.count * menuItem.item_amount;
      store.commit("setMenuCart", this.$store.state.menuItemCart);
    },
    deleteItem(menuItem) {
      this.$store.state.menuItemCart.order_items.splice(
          this.$store.state.menuItemCart.order_items.indexOf(menuItem),
          1
      );
      let order_total = this.calcOrderTotal();
      this.$store.state.menuItemCart.order_total = order_total;
      store.commit("setMenuCart", this.$store.state.menuItemCart);
    },
    calcOrderTotal() {
      let order_total = Object.values(
          this.$store.state.menuItemCart.order_items
      ).reduce((a, {total_amount}) => {
        return a + total_amount;
      }, 0);
      return order_total;
    },
    orderFinalCart() {
      let order_total = this.calcOrderTotal();
      let tenant_id = this.$route.params.tenant_id;
      this.tenant_id = tenant_id;
      this.$store.state.menuItemCart.tenant_id = tenant_id;

      this.$store.state.menuItemCart.order_total = order_total;
      this.$store.state.menuItemCart.sub_total = this.subTotal;
      this.$store.state.menuItemCart.service_charge = this.serviceCharge;
      this.$store.state.menuItemCart.total_discount = this.discountTotal;

      const params = {
        order_cart: this.$store.state.menuItemCart,
        tenant_id: this.tenant_id,
      };
      this.orderConfirm(params).then((data) => {
        if (!data.data.error) {
          this.$store.state.menuItemCart = {
            order_items: [],
            tenant_id: "",
            order_total: 0,
          };
          store.commit("clearMenuCart");
        }
      });
    },
    filterMenuCart(type) {
      if (this.$store.state.menuItemCart.order_items) {
        return this.$store.state.menuItemCart.order_items.filter((item) => {
          return item.item.menu_category_all[0].category.billing == type;
        });
      } else {
        return [];
      }
    },
  },
  mounted() {
    this.tenant_id = this.$route.params.tenant_id;
  },
};
</script>
<style>
.cart-btn {
  padding: 5px 10px;
  font-size: 12px;
  border: none;
  font-weight: bolder;
  background-color: #28a745;
  color: transparent;
  border-radius: 5px;
  cursor: pointer;
  outline: none; /* Remove focus outline */
  width: 100%;
  height: 30px;
}


.menu-category-header {
  background-color: #4d4d4d;
}

.badge-initial {
  position: inherit !important;
}

.vue-star-rating {
  display: block !important;
}
</style>
<style scoped>
.vue-star-rating {
  display: block;
}

.order-summary-table {
  color: #ffffff;
}
</style>

<style>
.dropdown-menu {
  color: #ffffff !important;
  background-color: #171718 !important;
  border-color: #171718 !important;
}

.dropdown-item:hover,
.dropdown-item:focus {
  text-decoration: none;
  color: #ffffff;
  background-color: #161616 !important;
}
</style>

<style scoped>
.menu-item-cart-wrapper {
  max-height: 60vh;
  overflow: auto;
}

.c-icon {
  margin-right: 0.3rem;
}

.c-header .c-header-nav .c-header-nav-link,
.c-header .c-header-nav .c-header-nav-btn {
  color: rgb(255 255 255) !important;
}

.cart-size {
  width: 22rem !important;
  background-color: #000000 !important;
  color: #ffffff;
  border: 1px solid #171718 !important;
}

@media (max-width: 768px) {
  /* .cart-item-mobile{
  display: inline-flex;
    flex-wrap: nowrap;
    align-items: center;
} */
}
</style>
