<template>
  <div>
    <RestaurantMenuViewNew/>
  </div>
</template>

<script>
import RestaurantMenuViewNew from "@/component/RestaurantMenuView/RestaurantMenuViewNew.vue";

export default {
  name: 'ResturantMenuViewNew',
  components: {
    RestaurantMenuViewNew,
  }
}
</script>

<style scoped>



</style>
